.mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"]) .mat-expansion-panel-header:hover {
  background: transparent;
}

/* for summernote in safari brouser */
.note-editor.note-frame {
  -webkit-user-select: initial;
  user-select: initial;
}

.note-editor.note-airframe .note-statusbar,
.note-editor.note-frame .note-statusbar {
  display: none !important;
}

.note-editing-area .note-editable {
  overflow: hidden !important;
  overflow-y: auto !important;
}

.info_material_icon {
  font-size: 1rem !important;
  color: #8f63cf !important;
}

.info_material_icon_big {
  font-size: 1.7rem !important;
  color: #8f63cf !important;
}