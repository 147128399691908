@media (min-width: 1921px) and (max-width: 2600px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1601px) and (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1367px) and (max-width: 1600px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 800px) and (max-width: 1025px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 390px) and (max-width: 400px) {
  html {
    font-size: 16px;
  }
}
