/* Meaning Sphere theme */

/* Global Theme settings */
$bg-color: #fff;
$bg-color-theme: #1f1547;
$color: #1f1547;

/* cards styles */
$card-radius: 0.75rem;
$main-crad-padding: 0.938rem;
$main-crad-bg: $bg-color;
$main-crad-2-bg: transparent;
$main-crad-2-padding: 0;
$sub-crad-padding: 0.938rem;
$sub-crad-bg: $bg-color;

/* hr theme */
$hr-border-color: #67468e;
$hr-border-style1: 0.938rem;
$hr-border-style2: 0.625rem;
$hr-border-style3: 0.313rem;

/* buttons Theme */
$button-bg: #a2d45e;
$button-color: #67468e;
$button-border-color: #67468e;

$button-hover-bg: #67468e;
$button-hover-color: #fff;
$button-border-color: #67468e;

$button-white-bg: #fff;
$button-white-color: #67468e;
$button-white-border-color: #67468e;

$button-cancle-bg: grey;
$button-cancle-color: #fff;
$button-cancle-border-color: #fff;

$button-padding-5: 0.313rem;
$button-padding-10: 0.625rem;
$button-border-radius: 0.313rem;

/* tab Theme */

$tab-border-color: #67468e;

$tab-active-bg: #67468e;
$tab-active-color: #fff;

$tab-inactive-bg: #fff;
$tab-inactive-color: #67468e;

/* Input Theme */
$option-bg-color: #67468e;
$option-color: #fff;

/* Height and Widths */
$header-height: 5rem;
$sidebar-width: 5rem;
$sidebar-expanded-width: 18.75rem;
$main-gap-right-left: 3.125rem;
$main-gap-top-bottom: 3.125rem;

/* card heightd */

$card-gap-top-bottom: 1.875rem;
$card-actions: 2.5rem;
$h1-heading-height: 2.1rem+0;
$h2-headin-height: 1.5rem;
$h2-heading-height: 3.063rem;
$tab-height: 3rem;
$button-section-height: 3.75rem;
$dropdown-height: 4.375rem;
$dropdown-height-bottom-0: 3.438rem;
$search-height: 3.938rem;
$hr-height-default: 1.938rem;
$hr-height-10: 1.313rem;
$hr-height-5: 0.688rem;
$hr-height-none: 0.063rem;
$profile-card: 19.688rem;
$profile-network: $profile-card + $card-gap-top-bottom + 7.375rem+0.625rem;

/* Margins and Paddings */
html {
  --scrollbarBG: rgba(240, 248, 255, 0.059);
  --thumbBG: rgba(240, 248, 255, 0.059);
}

.whitePurpleTheme {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  width: 100% !important;
  background: #1f1547;
}

.kt-main {
  width: calc(100% - ($sidebar-width + $main-gap-right-left));
  transition: 0.5s width linear;
  overflow-y: auto;
  height: calc(100vh - $header-height);
  margin-top: 0 !important;
  overflow-x: hidden;
}

.notloggedin .kt-main {
  height: auto;
}

.kt-sidebar {
  width: $sidebar-width;
  transition: 0.5s width linear;
}

.expandSidebar .kt-main {
  width: calc(100% - ($sidebar-expanded-width + $main-gap-right-left));
  transition: 0.5s width linear;
}

.expandSidebar .kt-sidebar {
  width: $sidebar-expanded-width;
  transition: 0.5s width linear;
}

.expandSidebar-hover .kt-sidebar {
  width: $sidebar-expanded-width;
  transition: 0.5s width linear;
  position: fixed;
  z-index: 9999;
}

.image-input,
.delete_image_video,
.delete_icon_img {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .kt-sidebar {
    visibility: visible !important;
    display: block;
    position: relative;
  }

  .expandSidebar .kt-sidebar {
    visibility: visible !important;
    position: fixed;
    width: 14rem;
    transition: 0.5s width linear;
    z-index: 1;
  }

  .expandSidebar .kt-main {
    transition: 0.5s width linear;
    width: calc(100% - 11.875rem);
    margin-left: 16rem !important;
  }
}


.whitePurpleTheme .back_card_background_clr,
.whitePurpleTheme .card_background_color_class {
  background: $bg-color  !important;
}

/* all colors */
.whitePurpleTheme div#kt_content * {
  color: $color;
}
div#kt_content {
  padding: 0rem;
}
.whitePurpleTheme td,
.whitePurpleTheme table {
  color: $color  !important;
}

.whitePurpleTheme div#kt_content .mentor_g_icon {
  color: $bg-color  !important;
}

.whitePurpleTheme .fa-search::before {
  color: $color  !important;
}

.whitePurpleTheme .welcomMeaninSphere h1 {
  color: $bg-color  !important;
}

.whitePurpleTheme div#kt_content .text-danger,
.whitePurpleTheme div#kt_content .text-danger i {
  color: #e06f23 !important;font-size: 0.9rem !important;height:0.75rem !important;
}
.whitePurpleTheme div#kt_content .text-success,
.whitePurpleTheme div#kt_content .text-success i {
  color:rgb(162, 212, 94) !important;font-size: 0.9rem !important;height:0.75rem !important;
}

.whitePurpleTheme div#kt_content .kt-footer__wrapper * {
  color: $bg-color  !important;
  font-size: 0.8rem !important;
  @media screen and (max-width:767px) {font-size:0.65rem !important;}
}

.whitePurpleTheme div#kt_content .meaningsphereAside>ul>li span.menu-lable {
  color: $bg-color  !important;
  padding: 0.313rem !important;
}

.whitePurpleTheme .color-white {
  color: #fff !important;
}

.whitePurpleTheme .loadingWave {
  border-color: #a2d45e !important;
  color: #67468e !important;
  border: 0.063rem solid #67468e !important;
}

/* tabs */

.whitePurpleTheme .mat-tab-label-active {
  background: $tab-active-bg  !important;
  border-radius: 0.188rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-family: "Lato" !important;
  opacity: 1;
}

.whitePurpleTheme .mat-tab-label-active .mat-tab-label-content {
  color: $tab-active-color  !important;
  font-family: "Lato" !important;
}

.whitePurpleTheme .mat-tab-labels {
  display: flex;
  border-bottom: 0.063rem solid $tab-border-color;
  font-family: "Lato" !important;
}

.whitePurpleTheme .mat-tab-label,
.whitePurpleTheme .mat-tab-label-content {
  font-weight: 700 !important;
  font-family: "Lato" !important;
}

.whitePurpleTheme .mat-tab-body-content {
  padding: 0 !important;
  overflow-x: hidden;
}

.whitePurpleTheme .matTabsVertical .mat-tab-labels {
  flex-direction: column !important;
  width: 100% !important;
}

.whitePurpleTheme .matTabsVertical .mat-tab-label {
  width: 6.25rem !important;
}

.whitePurpleTheme .matTabsVertical .mat-tab-body-wrapper {
  width: calc(100% - 10rem) !important;
}

.whitePurpleTheme .matTabsVertical .mat-tab-header {
  width: 10rem !important;
}

.whitePurpleTheme .matTabsVertical .mat-tab-group {
  flex-direction: row !important;
}

.whitePurpleTheme .matTabsVertical .mat-tab-body-content {
  padding: 0 0.625rem !important;
}

.whitePurpleTheme .mat-tab-label-content {
  display: block !important;
}

/* Form fields */
.whitePurpleTheme .mat-input-element {
  border-radius: 0.188rem !important;
  caret-color: $color  !important;
  -webkit-caret-color: $color  !important;
  -moz-caret-color: $color  !important;
  font-family: "Lato" !important;
}

.whitePurpleTheme mat-form-field input,
.whitePurpleTheme input {
  border-radius: 0.188rem !important;
  padding: 0.625rem 0.625rem !important;
  caret-color: $color  !important;
  -webkit-caret-color: $color  !important;
  -moz-caret-color: $color  !important;
  border-radius: 0.5rem !important;
}

.whitePurpleTheme mat-form-field input::placeholder {
  color: $color  !important;
}

.whitePurpleTheme .cdk-text-field-autofill-monitored:not(:autofill) {
  background: rgba(31, 21, 71, 0.2) !important;  color: $color  !important;
}

.whitePurpleTheme .cdk-text-field-autofill-monitored.time_set {
  background: transparent !important;
}

.whitePurpleTheme .mobile-pad,
.whitePurpleTheme .note-editing-area .note-editable,
.whitePurpleTheme .mat-input-element {
  background: rgba(31, 21, 71, 0.2) !important;color:$color;
}

.whitePurpleTheme input.my_search_input::-ms-input-placeholder {
  color: #1f154791 !important;
}

.whitePurpleTheme input.my_search_input::-webkit-input-placeholder {
  color: #1f154791 !important;
}

.whitePurpleTheme input.my_search_input::placeholder {
  color: #1f154791 !important;
}

.whitePurpleTheme input.my_search_input::placeholder {
  color: red;
  opacity: 1;
  /* Firefox */
}

.whitePurpleTheme .mat-menu-panel,
.whitePurpleTheme .mat-dialog-popup-profile {
  background: $bg-color  !important;
  border: 0.063rem solid #67468e !important;
}

.whitePurpleTheme .ng-dropdown-panel.ng-select-bottom,
.whitePurpleTheme .select,
.whitePurpleTheme select {
  padding: 0.188rem 0.188rem 0.188rem 0.188rem !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: $color  !important;
  border: 0.063rem solid #67468e !important;
  -webkit-appearance: listbox !important;
  -moz-appearance: listbox !important;
  -webkit-appearance:  menulist !important;
}

.whitePurpleTheme select option,
.whitePurpleTheme select.guid-select option,
.whitePurpleTheme select.general_select option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: $option-bg-color  !important;
  color: $option-color  !important; 
}

.whitePurpleTheme select {
  color: $color  !important;
  border-radius: 0.5rem !important;
}

/* Input search style */
.mc_search {
  padding: 1.25rem 1.25rem 0.625rem 1.25rem !important;
}

/* input style 1 (search with filter and icon) */

.search-style1>input {
  border: 0.063rem solid #67468e !important;
  border-radius: 4rem !important;
  padding: 0.625rem 5.625rem 0.625rem 1.875rem !important;
  margin: 0.313rem 0 !important;
}

.search-style1>.icons-sets-new {
  position: absolute;
  right: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.search-style1>.icons-sets-new .fa.fa-filter.fa-one {
  border-left: 0.063rem solid #000;
  padding: 0 0.625rem;
}

.search-style1>.icons-sets-new .fa.fa-search.fa-two {
  border-left: 0.063rem solid #000;
  padding: 0 0.625rem;
}

/* input style 2 (search with refresh icon and search icons) */
.search-style2>input {
  border: 0.063rem solid #67468e !important;
  border-radius: 4rem !important;
  padding: 0.625rem 3.125rem 0.625rem 1.875rem !important;
  margin: 0.313rem 0 !important;
}

.search-style2>.icons-sets-new {
  position: absolute;
  right: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.search-style2>.icons-sets-new>img {
  width: 1.25rem;
  height: 1.25rem;
}

/* input style 3 (search with icon) */

.search-style3>input {
  border: 0.063rem solid #67468e !important;
  height: 2.438rem !important;
  padding: 0.313rem 0.625rem !important;
  border-radius: 4rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin: 0.313rem 0 !important;

  @media screen and (max-width:1366px) {
    padding: 0.313rem 0.625rem 0.125rem 0.625rem !important;
  }
}

.whitePurpleTheme .search-style3 .searchIconClass {
  border: 0.063rem solid #67468e !important;
  height: 2.438rem !important;
  padding: 0.313rem !important;
  border-top-right-radius: 1.25rem !important;
  border-bottom-right-radius: 1.25rem !important;
  margin-top: 0.313rem;
  @media screen and (min-width:1366px) and (max-width:1380px) {
    .whitePurpleTheme .search-style3 .searchIconClass {
      height: 2.4rem !important;
    }
  }
}

@media screen and (min-width:1360px) and (max-width:1365px) {
  .whitePurpleTheme .search-style3 .searchIconClass {
    height: 2.3rem !important;
  }

}

/* popups */
@media screen and (max-width:639px) {
  .whitePurpleTheme .cdk-overlay-pane {
    width: 80% !important;
  }
}

.whitePurpleTheme .mat-dialog-content {
  overflow-x: hidden !important;
  max-height: inherit !important;
}

.whitePurpleTheme .mat-dialog-container {
  position: relative !important;
  padding: 1.25rem !important;
}

.whitePurpleTheme .mat-dialog-popup {
  background: $bg-color  !important;

}

.whitePurpleTheme .mat-dialog-popup * {
  color: $color  !important;
}

.whitePurpleTheme .popup_card_title {
  color: $color  !important;
  font-weight: 500;
}

.whitePurpleTheme .cdk-text-field-autofill-monitored,
.whitePurpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-label,
.whitePurpleTheme mat-label.ng-star-inserted,
.whitePurpleTheme .memg-btn-go,
.whitePurpleTheme .mat-form-field-label-wrapper,
.whitePurpleTheme .mat-form-field-label,
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: $color  !important;
}

.whitePurpleTheme .mat-menu-panel button.mat-menu-item {
  color: $color  !important;
}

.whitePurpleTheme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: transparent !important;
}

.whitePurpleTheme .prof_list_title {
  color: $color  !important;
}

.whitePurpleTheme p:not(.span-host),
.whitePurpleTheme strong,
.whitePurpleTheme a,
.whitePurpleTheme u {
  color: $color  !important;
}

.whitePurpleTheme p.span-host {
  color: $bg-color  !important;
}

.whitePurpleTheme .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: $color  !important;
}

.whitePurpleTheme .mat-radio-outer-circle {
  border-color: #000 !important;
}
.whitePurpleTheme  .RadioContainer mat-radio-group mat-radio-button .mat-radio-outer-circle{
  margin-top: 0.313rem !important;
  }
/* button */

.whitePurpleTheme button,
.whitePurpleTheme .btn {
  outline: none !important;
  border-radius: $button-border-radius  !important;
}


.whitePurpleTheme button.btn.go_btn {
  border: none;
  padding: 0rem 1.875rem;
  margin: 0rem 0.625rem 0rem 0.625rem;
}

.whitePurpleTheme .begin_quiz {
  width: max-content;
  padding: $button-padding-5;
  font-weight: 600;
  border: none;
  margin: 0 0rem;
  text-transform: uppercase;
  word-spacing: 0;
  transition: all 0.5s;
  cursor: pointer;
}

.whitePurpleTheme .begin_quiz_round {
  width: 21.563rem;
  height: 21.563rem;
  border-radius: 50% !important;
  text-align: center;
  padding: 0rem 0rem 0rem 0rem !important;
  line-height: 1;
}

.whitePurpleTheme .begin_quiz,
.whitePurpleTheme .go_btn,
.whitePurpleTheme .guid-swich-btn,
.whitePurpleTheme button.publish {
  background-color: $button-bg  !important;
  color: $button-color  !important;
  border: 0.063rem solid $button-border-color  !important;
  margin: 0rem 0.313rem;
}

.whitePurpleTheme .begin_quiz.begin_white {
  background-color: $button-white-bg  !important;
  color: $button-white-color  !important;
  border: 0.063rem solid $button-white-border-color  !important;
}

.whitePurpleTheme .canceled_btn {
  background-color: $button-cancle-bg  !important;
  color: $button-cancle-color  !important;
  border: 0.063rem solid $button-cancle-border-color  !important;
  cursor: pointer;
  outline: none !important;
  margin-right: 0 !important;
}

.whitePurpleTheme .welcom_butto {
  background-color: grey !important;
  color: #fff !important;
  border: 0.063rem solid #fff !important;
  cursor: pointer;
  outline: none !important;
  /* margin-right: 0.625rem; */
  /* margin-bottom: 0.625rem; */
  padding: 0.3rem 0.5rem;
  border-radius: 0.75rem !important;
  font-size: 0.75rem;
}

.whitePurpleTheme .welcom_butto.active {
  background-color: $button-color  !important;
  color: $button-cancle-color  !important;
  cursor: pointer;
}

.whitePurpleTheme div#kt_content .begin_quiz:hover,
.whitePurpleTheme .begin_quiz:hover,
.whitePurpleTheme .canceled_btn:hover,
.whitePurpleTheme div#kt_content .go_btn:hover,
.whitePurpleTheme div#kt_content .guid-swich-btn:hover,
.whitePurpleTheme div#kt_content .publish:hover {
  background-color: $button-hover-bg  !important;
  color: $button-hover-color  !important;
  border: 0.063rem solid $button-border-color  !important;
}

.whitePurpleTheme .comment-submit-btn {
  background-color: transparent !important;
  border: none;
  top: 0.75rem !important;
  position: relative;
}

/* close button styles */
/* style1 */
.whitePurpleTheme .close-dialog {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(50%, -50%) !important;
  background: #fff !important;
  opacity: 1 !important;
  padding: 0.625rem !important;
  border-radius: 50% !important;
  line-height: 1 !important;
  outline: none !important;
  border: 0 !important;
  cursor: pointer;
}

/* style2 */
.whitePurpleTheme .close-dialog-small {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(50%, -50%) !important;
  background: #fff !important;
  opacity: 1 !important;
  padding: 0.313rem !important;
  border-radius: 50% !important;
  line-height: 0.8 !important;
  outline: none !important;
  border: 0 !important;
  font-size: 1rem;
}

/* style3 */
.whitePurpleTheme .close-dialog-transparent {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(50%, -50%) !important;
  background: transparent !important;
  opacity: 1 !important;
  padding: 0.625rem !important;
  border-radius: 50% !important;
  line-height: 1 !important;
  outline: none !important;
  border: 0 !important;
}

.whitePurpleTheme .close-dialog-purple {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(50%, -50%) !important;
  background: #67468e !important;
  opacity: 1 !important;
  padding: 0.625rem !important;
  border-radius: 50% !important;
  line-height: 1 !important;
  outline: none !important;
  border: 0 !important;
  color: #fff !important;
}

/* style4 */
.whitePurpleTheme button.modal_close_button_top {
  font-weight: 700 !important;
  font-size: 1rem !important;
  border: none !important;
  min-width: -moz-max-content !important;
  color: #080808 !important;
  background-color: white !important;
  border-radius: 50% !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(50%, -50%) !important;
}

/* style5 */
.whitePurpleTheme button.modal_close_button_top_without_title {
  font-weight: 700 !important;
  font-size: 1rem !important;
  border: none !important;
  min-width: -moz-max-content !important;
  color: #080808 !important;
  background-color: white !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(50%, -50%) !important;
  border-radius: 50% !important;
}

/* material time picker Styles */
.whitePurpleTheme ngx-material-timepicker-toggle {
  position: absolute;
  right: 0;
  color: $bg-color;
}
.whitePurpleTheme ngx-material-timepicker-toggle * {
  color: black !important;
  fill: black !important;
}

.whitePurpleTheme ngx-material-timepicker-toggle {
  height: 2.938rem;
  display: flex;
  align-items: center;
  margin-top: -0.125rem;
  padding-left: 0.313rem;
  background: $bg-color  !important;

}

.whitePurpleTheme ngx-material-timepicker-toggle button {
  background: $bg-color  !important;

}

.whitePurpleTheme .timepicker__header {
  background: $bg-color  !important;
  border-bottom: 0.063rem solid $color;
}

.whitePurpleTheme .timepicker__body .whitePurpleTheme .clock-face__number>span.active {
  background: #67468e !important;
}

.whitePurpleTheme .clock-hand {
  background: #67468e !important;
  color: #67468e !important;
  border-color: #67468e !important;
}

.whitePurpleTheme .clock-face {
  background: rgba(52, 58, 94, 1) !important;
  border: 0.063rem solid rgba(255, 255, 255, 0.1);
}

.whitePurpleTheme .clock-face__number>span,
.whitePurpleTheme .clock-face__number--outer>span {
  background-color: transparent !important;
}

.whitePurpleTheme .clock-face :not(.clock-face__number, .clock-face__number--outer span) {
  background: #67468e !important;
}

.whitePurpleTheme .clock-face__number--outer>span {
  color: $bg-color  !important;
}

.whitePurpleTheme .timepicker-dial__time {
  color: $color  !important;
}

.whitePurpleTheme .timepicker-dial__item_active,
.whitePurpleTheme .timepicker-dial__item,
.whitePurpleTheme .timepicker-dial__item_active,
.whitePurpleTheme .timepicker-dial__item {
  color: $color  !important;
}

.whitePurpleTheme .timepicker-button>span.active {
  color: $color  !important;
}

.whitePurpleTheme .timepicker-button {
  color: $color  !important;
}

.whitePurpleTheme .timepicker__body {
  background: $bg-color  !important;
  border-bottom: 0.063rem solid $color;
}

.whitePurpleTheme .timepicker__actions {
  background: $bg-color  !important;
}

.whitePurpleTheme .clock-face__number>span.disabled {
  color: $color  !important;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

ngx-material-timepicker-toggle * {
  color: #fff !important;
  fill: #fff !important;
}

.ngx-timepicker-control {
  background: transparent !important;
}
.ngx-timepicker-control__input {
  background: transparent !important;
}

.period-control__arrow,
.ngx-timepicker-control__arrow {
  color: #ffffff66 !important;
}

.timepicker__header {
  background: #343a5e !important;
  border-bottom: 0.063rem solid #fff;
}

/* end time picker */
/* material Calender Styles */
.whitePurpleTheme svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
  color: $color;
}

.whitePurpleTheme .mat-datepicker-content {
  display: block;
  border-radius: 1.25rem !important;
  background: $bg-color  !important;
  color: $color  !important;
}

.whitePurpleTheme .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: $color  !important;
  opacity: 0.7;
}

.whitePurpleTheme .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
  background-color: #67468e !important;
}

.whitePurpleTheme .mat-calendar-body-cell-content {
  color: $color  !important;
  border-color: transparent;
}

.whitePurpleTheme .mat-calendar-body-label {
  color: $color  !important;
}

.whitePurpleTheme .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  color: $bg-color  !important;
}

.whitePurpleTheme .mat-calendar-body-selected {
  background-color: #67468e !important;
  color: $bg-color  !important;
}

.whitePurpleTheme .mat-calendar-table-header th {
  text-align: center;
  padding: 0 0 0.5rem 0;
  color: $color  !important;
}

.whitePurpleTheme button.mat-calendar-period-button.mat-button.mat-button-base {
  border: 0.063rem solid $color  !important;
}

.whitePurpleTheme .mat-datepicker-toggle,
.whitePurpleTheme .mat-datepicker-content .mat-calendar-next-button,
.whitePurpleTheme .mat-datepicker-content .mat-calendar-previous-button {
  color: $color  !important;
}

.whitePurpleTheme .mat-calendar-arrow {
  border-top-color: $color  !important;
}

.whitePurpleTheme .card.archive_card,
.whitePurpleTheme .curator_card_main,
.whitePurpleTheme .subCardClas {
  padding: 0.625rem !important;
  border: none !important;
  margin: 0.75rem 0rem !important;
  border: 0.063rem solid $color  !important;
  border-radius: 0.75rem !important;
}

.whitePurpleTheme .card.archive_card:hover {
  box-shadow: 0.125rem 0.313rem #c0bccf !important;
}

.whitePurpleTheme span.border-linespa::before {
  border-left: 0.063rem solid $color;
}

.whitePurpleTheme span.border-linespa::after {
  border-right: 0.063rem solid $color;
}

.whitePurpleTheme .absolutueCenter {
  position: absolute !important;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}

.whitePurpleTheme .h-100 {
  height: 100% !important;
}

.whitePurpleTheme .w-100 {
  width: 100% !important;
}

.whitePurpleTheme .w-50 {
  width: 50% !important;
}

.whitePurpleTheme .w-60 {
  width: 60% !important;
}

.whitePurpleTheme .w-70 {
  width: 70% !important;
}

.whitePurpleTheme .w-30 {
  width: 30% !important;
}

.whitePurpleTheme .w-40 {
  width: 40% !important;
}

.whitePurpleTheme .w-25 {
  width: 25% !important;
}

.whitePurpleTheme .w-auto {
  width: auto !important;
}

/* alignments */
.whitePurpleTheme .text-align-center {
  text-align: center !important;
}

.whitePurpleTheme .text-align-left {
  text-align: left !important;
}

.whitePurpleTheme .text-align-right {
  text-align: right !important;
}

.whitePurpleTheme .text-align-justify {
  text-align: justify !important;
}

/* flex box */

.whitePurpleTheme .d-flex {
  display: flex !important;
}
.whitePurpleTheme .d-inline-flex {
  display: inline-flex !important;
}
.whitePurpleTheme .flex-wrap-no {
  flex-wrap: nowrap;
}

.whitePurpleTheme .flex-wrap {
  flex-wrap: wrap;
}

.whitePurpleTheme .flex-direction-column {
  flex-direction: column !important;
}

.whitePurpleTheme .justify-content-end {
  justify-content: flex-end !important;
}

.whitePurpleTheme .justify-content-start {
  justify-content: flex-start !important;
}

.whitePurpleTheme .justify-content-center {
  justify-content: center !important;
}

.whitePurpleTheme .justify-content-between {
  justify-content: space-between !important;
}

.whitePurpleTheme .justify-content-arround {
  justify-content: space-around !important;
}

.whitePurpleTheme .justify-content-evenly {
  justify-content: space-evenly !important;
}

.whitePurpleTheme .align-items-center {
  align-items: center !important;
}

.whitePurpleTheme .align-items-end {
  align-items: flex-end !important;
}

.whitePurpleTheme .align-items-start {
  align-items: flex-start !important;
}

/* list styles */
.whitePurpleTheme .list-style-dotted {
  list-style: disc;
  list-style-position: inside;
}

.whitePurpleTheme .list-style-none {
  list-style: none;
}

.whitePurpleTheme {
  font-family: Lato !important;
}

.whitePurpleTheme .page_title {
  font-size: 2.75rem !important;
  font-weight: 400 !important;
}

/* font-sizes and weights */
.whitePurpleTheme h1 {
  font-size: 1.75rem !important;
  font-weight: 700 !important;
}

.whitePurpleTheme h1.fs-24 {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
}

.whitePurpleTheme h1.fs-20 {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

.whitePurpleTheme h1.fs-18 {
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  @media only Screen and (max-width: 1100px) {font-size: 1rem !important;}
}

.whitePurpleTheme h1.fs-16 {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.whitePurpleTheme h1.fs-14 {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.whitePurpleTheme h1.fs-12 {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}

.whitePurpleTheme .fs-10 {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}

.whitePurpleTheme h2 {
  font-size: 1.25rem ;
  font-weight: 700 ;
}

.whitePurpleTheme h3 {
  font-size: 1rem ;
  font-weight: 600 ;
}
.feedBox .feedStats ul li .icon img{
  width: 2rem !important;
  height: 2rem !important;
}
.whitePurpleTheme p,
.whitePurpleTheme option,
.whitePurpleTheme label,
.whitePurpleTheme input,
.whitePurpleTheme select,
.whitePurpleTheme textarea,
.whitePurpleTheme li,
.whitePurpleTheme div,
.whitePurpleTheme span,
.whitePurpleTheme small,
.whitePurpleTheme button {
  font-size: 1.125rem;
  font-weight: 400 !important;
  font-family: 'Lato' !important;
  hyphens: none !important;
}
@media screen and (min-width: 500px) and (max-width: 1200px) {

  .whitePurpleTheme p,
  .whitePurpleTheme option,
  .whitePurpleTheme label,
  .whitePurpleTheme input,
  .whitePurpleTheme select,
  .whitePurpleTheme textarea,
  .whitePurpleTheme li,
  .whitePurpleTheme div,
  .whitePurpleTheme span,
  .whitePurpleTheme small,
  .whitePurpleTheme button {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    hyphens: none !important;
  }
}

/* Hr styles */

.whitePurpleTheme hr {
  border-color: $hr-border-color  !important;
  margin: $hr-border-style1 0 !important;
}

.whitePurpleTheme .hr-10 {
  margin: $hr-border-style2 0 !important;
}

.whitePurpleTheme .hr-5 {
  margin: $hr-border-style3 0 !important;
}

/* card styles */

.whitePurpleTheme .mat-card {
  padding: $main-crad-padding  !important;
  margin: calc($main-gap-top-bottom/2) 0 !important;
  border-radius: $card-radius  !important;
}

.whitePurpleTheme .mat-card>h1 {
  margin-bottom: 0 !important;
}

.whitePurpleTheme div#kt_content .mat-card-actions {
  margin-top: $main-crad-2-padding  !important;
  margin-bottom: $main-crad-2-padding  !important;
}

.whitePurpleTheme .mat-card-style2 {
  padding: $main-crad-2-padding  !important;
  margin: calc($main-gap-top-bottom/2) 0 !important;
  background: none !important;
}

.whitePurpleTheme .mat-card-style2 .sub-card-right {
  padding-left: 0.781rem !important;
}

.whitePurpleTheme .mat-card-style2 .sub-card-left {
  padding-right: 0.781rem !important;
}

.whitePurpleTheme .network_section {
  margin: calc($main-gap-top-bottom/2) 0 !important;
}

.whitePurpleTheme .mat-card .mat-card,
.whitePurpleTheme .mat-card-style2 .mat-card,
.whitePurpleTheme .network_section .mat-card {
  margin: 0 0 0.625rem 0 !important;
}

.whitePurpleTheme .profile_card {
  padding: $sub-crad-padding  !important;
  border: 0.063rem solid rgba(52, 58, 94, 1);
  background: $sub-crad-bg  !important;
  border-radius: $card-radius  !important;
  margin: 0 0 0.625rem 0 !important;
}

.whitePurpleTheme .mat-dialog-popup-profile .profile_card {
  background: $sub-crad-bg  !important;
  color: $color  !important;
}

/* Sub card sttles */

.sub-card-image {
  margin: -0.625rem -0.625rem 0.938rem -0.625rem !important;
}

.sub-card-style1 h3.only_two_line_show {
  min-height: 2.438rem !important;
}

.sub-card-style1 .tickIconClass {
  height: 1rem !important;
  width: 1rem !important;
}

.sub-card-style1 .mat-icon {
  margin-top: -0.125rem !important;
}

.sub-card-style1 .sub-card-description-title {
  text-decoration: underline;
}

.sub-card-style1 .sub-card-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4 !important;
  -moz-line-clamp: 4 !important;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  min-height: 6.875rem;
  height: auto !important;
}

/* Height css */

div[class*="card2-style-height-with"]::-webkit-scrollbar,
cdk-virtual-scroll-viewport[class*="card2-style-height-with"]::-webkit-scrollbar {
  width: 0.313rem !important;
}

div[class*="card2-style-height-with"]::-webkit-scrollbar-track,
cdk-virtual-scroll-viewport[class*="card2-style-height-with"]::-webkit-scrollbar-track {
  background: transparent !important;
}

div[class*="card2-style-height-with"]::-webkit-scrollbar-thumb,
cdk-virtual-scroll-viewport[class*="card2-style-height-with"]::-webkit-scrollbar-thumb {
  background-color: rgba(197, 197, 197, 1) !important;
  border-radius: 0.125rem !important;
  border: 0.188rem solid rgba(197, 197, 197, 1) !important;
}

div[class*="card2-style-height-with"],
cdk-virtual-scroll-viewport[class*="card2-style-height-with"] {
  padding-right: 0.313rem !important;
}

/* main card heights */
.whitePurpleTheme .full_screen_background_card_height,
.whitePurpleTheme .full_screen_background_card_height-Mng_circle,
.whitePurpleTheme .card2-style-height {
  height: calc(100vh - ($header-height + $main-gap-top-bottom )) !important;
  overflow: hidden !important;
}

@supports (-moz-appearance: none) {

  .whitePurpleTheme .full_screen_background_card_height,
  .whitePurpleTheme .full_screen_background_card_height-Mng_circle,
  .whitePurpleTheme .card2-style-height {
    height: calc(100vh - ($header-height + $main-gap-top-bottom + 0.6rem)) !important;
    overflow: hidden !important;
  }

  .whitePurpleTheme .card2-style-height::-webkit-scrollbar {
    width: 0rem !important;
  }

  .whitePurpleTheme .card2-style-height::-webkit-scrollbar-thumb {
    background: transparent !important;
  }

}

.whitePurpleTheme .card2-style-height-with-hr-heading {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $hr-height-default + $h1-heading-height + $card-gap-top-bottom )) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 1rem !important;
}

.whitePurpleTheme .card2-style-height-with-search-hr-heading {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $h1-heading-height + $search-height + $hr-height-default )) !important;
  padding-right: 0.5rem !important;
  padding-bottom: 1rem !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.whitePurpleTheme .card2-style-height-with-search-hr {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $search-height + $hr-height-default )) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 1rem !important;
}
.whitePurpleTheme .circle_dialog_host {
  height: calc(100vh - 24rem) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 1rem !important;
}

.whitePurpleTheme .card2-style-height-with-tabs-hr-heading {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $h1-heading-height + $tab-height + $hr-height-default )) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0.938rem;
  padding-right: 1rem !important;
}

.whitePurpleTheme .card2-style-height-with-tabs-hr-heading-cardactions {
  height: calc(100vh - ($header-height + ($main-gap-top-bottom) * 2 + $card-gap-top-bottom + $h1-heading-height + $tab-height + $hr-height-default + $card-actions + 0.938rem)) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0.938rem;
  padding-right: 1rem !important;
}

.whitePurpleTheme .card2-style-height-with-tabs-hr-heading-cardactions2 {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $h1-heading-height + $tab-height + $hr-height-default + $card-actions + 0.938rem)) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0.938rem;
}

.whitePurpleTheme .card2-style-height-with-tabs2-hr-heading-cardactions2 {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $h1-heading-height + $tab-height + $tab-height + $hr-height-default + $card-actions )) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0.938rem;
}

.whitePurpleTheme .card2-style-height-with-tabs-hr-heading-dropdown {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $h1-heading-height + $tab-height + $hr-height-default + $dropdown-height )) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0.938rem;
}

.whitePurpleTheme .card2-style-height-with-tabs-hr-dropdown2 {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $tab-height + $hr-height-default + $dropdown-height-bottom-0 )) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.whitePurpleTheme .card2-style-height-with-2tabs-hr {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $tab-height + $hr-height-default + $tab-height )) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0.938rem;
}

.whitePurpleTheme .card2-style-height-with-tabs-hr-heading2 {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $tab-height + $hr-height-default + $h2-heading-height )) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.whitePurpleTheme .card2-style-height-with-2tabs-hr-heading2 {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $tab-height + $tab-height + $hr-height-default )) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0.938rem;
}

.whitePurpleTheme .card2-style-height-with-2tabs-2hr-heading {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $h1-heading-height + $tab-height + $tab-height + $hr-height-default + $hr-height-default )) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0.938rem;
}

.whitePurpleTheme .card2-style-height-with-tabs-hr-buttons {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $button-section-height + $tab-height + $hr-height-default )) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 0.938rem;
}

.whitePurpleTheme .card2-style-height-with-hr-buttons {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $card-gap-top-bottom + $hr-height-default + $card-actions )) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.whitePurpleTheme .card2-style-height-with-hr-heading-actions {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $hr-height-default + $h1-heading-height + $card-gap-top-bottom + $card-actions )) !important;
  overflow-x: hidden;
  padding-right: 0.3rem;
  overflow-y: auto;
}

.mat-card--height-lvl1 {
  height: calc(100vh - ($header-height + $main-gap-top-bottom)) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.mat-card--height-lvl1.profile_card {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* left menu card heights */

.left-menu-card-left {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $profile-network + $card-gap-top-bottom + $h2-headin-height + $hr-border-style1 )) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.left-menu-card-left-menu-height-with-profile-network-1-3-h2b-hr {
  height: calc(100vh - ($header-height + $main-gap-top-bottom + $profile-network + $card-gap-top-bottom + $h2-headin-height + $hr-border-style1 ) / 3) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.left-menu-card-left-menu-height-with-profile-network-1-2-h2b-hr {
  height: calc(100vh - 37rem) !important;

  margin-top: 0 !important;

  margin-bottom: 0 !important;

  overflow-x: hidden;

  overflow-y: auto;


  @media screen and (min-width: 1024px) and (max-width: 1368px) {
    height: calc(100vh - 32rem) !important;
    padding-right: 0.25rem !important;
  }
}

.whitePurpleTheme .card-height-popup-with-heading {
  height: calc(100vh - 15.625rem) !important;
  overflow: hidden;
  overflow-y: auto;
}

/* image size */

.image-Enlarg-15 {
  margin: -0.938rem -0.938rem 0.938rem -0.938rem !important;
}

.sub-card-image img,
.image-Enlarg-15 img {
  border-top-right-radius: 0.75rem !important;
  border-top-left-radius: 0.75rem !important;
  max-width: 100% !important;
  width: 100% !important;
  height: 9rem !important;
  object-fit: cover !important;
}

.w-250-pp {
  width: 12rem !important;
  height: 12rem !important;
  border-radius: 50%;
}

.w-124-pm {
  width: 7.75rem !important;
  height: 7.75rem !important;
  border-radius: 50% !important;
}

.w-90-p {
  width: 5.625rem !important;
  height: 5.625rem !important;
  border-radius: 50%;
}

.w-124-title {
  width: 7.75rem !important;
  height: 7.75rem !important;
  border-radius: 50%;
}

/* padding */
.whitePurpleTheme .p-30 {
  padding: 1.875rem !important;
}

.whitePurpleTheme .p-25 {
  padding: 1.563rem !important;
}

.whitePurpleTheme .p-20 {
  padding: 1.25rem !important;
}

.whitePurpleTheme .p-15 {
  padding: 0.938rem !important;
}

.whitePurpleTheme .p-10 {
  padding: 0.625rem !important;
}

.whitePurpleTheme .p-5 {
  padding: 0.313rem !important;
}

.whitePurpleTheme .p-0 {
  padding: 0 !important;
}

/* padding top */
.whitePurpleTheme .p-top-80 {
  padding-top: 5rem !important;
}

.whitePurpleTheme .pt-30 {
  padding-top: 1.875rem !important;
}

.whitePurpleTheme .pt-25 {
  padding-top: 1.563rem !important;
}

.whitePurpleTheme .pt-20 {
  padding-top: 1.25rem !important;
}

.whitePurpleTheme .pt-15,
.whitePurpleTheme .p-top-15 {
  padding-top: 0.938rem !important;
}

.whitePurpleTheme .pt-10 {
  padding-top: 0.625rem !important;
}

.whitePurpleTheme .pt-5 {
  padding-top: 0.313rem !important;
}

/* padding-right */
.whitePurpleTheme .pr-30 {
  padding-right: 1.875rem !important;
}

.whitePurpleTheme .pr-40 {
  padding-right: 2.5rem !important;
}
.whitePurpleTheme .pr-35 {
  padding-right: 2.188rem !important;
}

.whitePurpleTheme .pr-25 {
  padding-right: 1.563rem !important;
}

.whitePurpleTheme .pr-20 {
  padding-right: 1.25rem !important;
}

.whitePurpleTheme .pr-15 {
  padding-right: 0.938rem !important;
}

.whitePurpleTheme .pr-10 {
  padding-right: 0.625rem !important;
}

.whitePurpleTheme .pr-5 {
  padding-right: 0.313rem !important;
}

/* padding-bottom */
.whitePurpleTheme .pb-30 {
  padding-bottom: 1.875rem !important;
}

.whitePurpleTheme .pb-25 {
  padding-bottom: 1.563rem !important;
}

.whitePurpleTheme .pb-20 {
  padding-bottom: 1.25rem !important;
}

.whitePurpleTheme .pb-15 {
  padding-bottom: 0.938rem !important;
}

.whitePurpleTheme .pb-10 {
  padding-bottom: 0.625rem !important;
}

.whitePurpleTheme .pb-5 {
  padding-bottom: 0.313rem !important;
}

/* padding-left */
.whitePurpleTheme .pl-30 {
  padding-left: 1.875rem !important;
}

.whitePurpleTheme .pl-25 {
  padding-left: 1.563rem !important;
}

.whitePurpleTheme .pl-20 {
  padding-left: 1.25rem !important;
}

.whitePurpleTheme .pl-15 {
  padding-left: 0.938rem !important;
}

.whitePurpleTheme .pl-10 {
  padding-left: 0.625rem !important;
}

.whitePurpleTheme .pl-5 {
  padding-left: 0.313rem !important;
}

/* margin */
.whitePurpleTheme .m-30 {
  margin: 1.875rem !important;
}

.whitePurpleTheme .m-25 {
  margin: 1.563rem !important;
}

.whitePurpleTheme .m-20 {
  margin: 1.25rem !important;
}

.whitePurpleTheme .m-15 {
  margin: 0.938rem !important;
}

.whitePurpleTheme .m-10 {
  margin: 0.625rem !important;
}

.whitePurpleTheme .m-5 {
  margin: 0.313rem !important;
}

.whitePurpleTheme .m-0 {
  margin: 0 !important;
}

/* margin top */
.whitePurpleTheme .mt-30 {
  margin-top: 1.875rem !important;
}

.whitePurpleTheme .mt-25 {
  margin-top: 1.563rem !important;
}

.whitePurpleTheme .mt-20 {
  margin-top: 1.25rem !important;
}

.whitePurpleTheme .mt-15 {
  margin-top: 0.938rem !important;
}

.whitePurpleTheme .mt-10 {
  margin-top: 0.625rem !important;
}

.whitePurpleTheme .mt-5 {
  margin-top: 0.313rem !important;
}

/* margin-right */
.whitePurpleTheme .mr-30 {
  margin-right: 1.875rem !important;
}

.whitePurpleTheme .mr-25 {
  margin-right: 1.563rem !important;
}

.whitePurpleTheme .mr-20 {
  margin-right: 1.25rem !important;
}

.whitePurpleTheme .mr-15 {
  margin-right: 0.938rem !important;
}

.whitePurpleTheme .mr-10 {
  margin-right: 0.625rem !important;
}

.whitePurpleTheme .mr-5 {
  margin-right: 0.313rem !important;
}

/* margin-bottom */
.whitePurpleTheme .mb-30 {
  margin-bottom: 1.875rem !important;
}

.whitePurpleTheme .mb-25 {
  margin-bottom: 1.563rem !important;
}

.whitePurpleTheme .mb-20 {
  margin-bottom: 1.25rem !important;
}

.whitePurpleTheme .mb-15 {
  margin-bottom: 0.938rem !important;
}

.whitePurpleTheme .mb-10 {
  margin-bottom: 0.625rem !important;
}

.whitePurpleTheme .mb-5 {
  margin-bottom: 0.313rem !important;
}

/* margin-left */
.whitePurpleTheme .ml-30 {
  margin-left: 1.875rem !important;
}

.whitePurpleTheme .ml-25 {
  margin-left: 1.563rem !important;
}

.whitePurpleTheme .ml-20 {
  margin-left: 1.25rem !important;
}

.whitePurpleTheme .ml-15 {
  margin-left: 0.938rem !important;
}

.whitePurpleTheme .ml-10 {
  margin-left: 0.625rem !important;
}

.whitePurpleTheme .ml-5 {
  margin-left: 0.313rem !important;
}

/*font size define */
.whitePurpleTheme,
.fs-44 {
  font-size: 2.75rem;
  font-weight: 700 !important;
}

.whitePurpleTheme,
.fs-28 {
  font-size: 1.75rem !important;
  font-weight: 700 !important;
}

.whitePurpleTheme,
.fs-20 {
  font-size: 1.25rem;
  font-weight: 700 !important;
}

.whitePurpleTheme,
.fs-18 {
  font-size: 1.125rem !important;
}

.whitePurpleTheme,
.fs-14 {
  font-size: 0.875rem;
}

.whitePurpleTheme,
.fs-16 {
  font-size: 1rem;
}

.whitePurpleTheme .profile_image {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 50% !important;
}

.whitePurpleTheme .pos-relative {
  position: relative !important;
}

.whitePurpleTheme .pos-absolute-custom {
  position: absolute !important;
}

.whitePurpleTheme .pos-absolute-right-center {
  position: absolute !important;
  right: 0 !important;
  top: 50% !important;
  transform: translatey(-50%) !important;
}

.whitePurpleTheme .pos-absolute-left-center {
  position: absolute !important;
  left: 0 !important;
  top: 50% !important;
  transform: translatey(-50%) !important;
}

.whitePurpleTheme .pos-absolute-top-right-corner {
  position: absolute !important;
  right: 0.625rem !important;
  top: 0.625rem !important;
}

.whitePurpleTheme .pos-absolute-top-right-corner-20 {
  position: absolute !important;
  right: 1.25rem !important;
  top: 1.25rem !important;
}

.whitePurpleTheme .pos-absolute-top-left-corner {
  position: absolute !important;
  left: 0.625rem !important;
  top: 0.625rem !important;
}

.whitePurpleTheme .pos-absolute-bottom-right-corner {
  position: absolute !important;
  right: 0.625rem !important;
  bottom: 0.625rem !important;
}

.whitePurpleTheme .pos-absolute-bottom-right-corner-20 {
  position: absolute !important;
  right: 1.25rem !important;
  bottom: 1.25rem !important;
}

.whitePurpleTheme .pos-absolute-bottom-left-corner {
  position: absolute !important;
  left: 0.625rem !important;
  bottom: 0.625rem !important;
}

.whitePurpleTheme .pos-absolute-top-right-corner-close {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(-50%, -50%) !important;
}

.whitePurpleTheme .pos-fixed {
  position: fixed !important;
}

.whitePurpleTheme .pos-static {
  position: static !important;
}

.eye-top-right-corner {
  width: 2rem;
  position: absolute;
  top: -0.5rem;
  right: 0.938rem;
  z-index: 9;
  background-color: #f9f9f9;
  border-radius: 50%;
  padding: 0rem 0.188rem;
}

/*27-april-2021 (By Shabeel) */
.whitePurpleTheme .word_break {
  overflow: hidden;
}

.whitePurpleTheme .word_break,
p {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;

  -ms-word-break: break-all !important;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all !important;
  /* Instead use this non-standard one: */
  word-break: break-word !important;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto !important;
  -moz-hyphens: auto !important;
  -webkit-hyphens: auto !important;
  hyphens: auto !important;
}

p:empty {
  height: auto !important;
}

.whitePurpleTheme .olny_two_line_show {
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden !important;
}

.whitePurpleTheme .only_one_line_show {
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
}

.whitePurpleTheme .only_two_line_show {
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden !important;
}

.whitePurpleTheme .olny_three_line_show {
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
  overflow: hidden !important;
  max-height: 5rem !important;
}

.whitePurpleTheme .only_three_line_show {
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
  overflow: hidden !important;
  max-height: 4.85rem;
}

.whitePurpleTheme .olny_four_line_show {
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 4 !important;
  overflow: hidden !important;
}

.whitePurpleTheme .olny_five_line_show {
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 5 !important;
  overflow: hidden !important;
}

.whitePurpleTheme .olny_six_line_show {
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 6 !important;
  overflow: hidden !important;
}

.whitePurpleTheme .olny_seven_line_show {
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 7 !important;
  overflow: hidden !important;
}

.whitePurpleTheme .olny_two_line_show p p {
  margin-bottom: 0 !important;
}

.whitePurpleTheme .only_one_line_show p p {
  margin-bottom: 0 !important;
}

.whitePurpleTheme .only_two_line_show p p,
.whitePurpleTheme .olny_three_line_show p p,
.whitePurpleTheme .only_three_line_show p p,
.whitePurpleTheme .olny_four_line_show p p,
.whitePurpleTheme .olny_five_line_show p p,
.whitePurpleTheme .olny_six_line_show p p,
.whitePurpleTheme .olny_seven_line_show p p {
  margin-bottom: 0 !important;
}

.arrow img {
  width: 100%;
}

.arrow {
  width: 2.125rem;
  transform: rotate(0deg);
  height: 2.125rem;
  transition: all 0.5s linear;
  float: right;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}
.arrow.active {
  transition: all 0.5s linear;
  transform: rotate(180deg);
}

.whitePurpleTheme .tooltip_design {
  color: #1f1547;
  padding: 0.625rem !important;
  border-radius: 0.75rem !important;
  font-size: 1rem !important;
  font-family: "Lato" !important;
  max-width: 31.875rem !important;
  text-align: left !important;
  border: 0.063rem solid #1f1547 !important;
  box-shadow: 0.125rem 0.313rem #1f1547 !important;
  height: auto !important;
}

.whitePurpleTheme .dis-prof .profile_img {
  width: 2.5rem !important;
  height: 2.5rem !important;
  margin: 0 !important;
  border-radius: 50%;
}

.whitePurpleTheme .roleType {
  background: #2b2052;
  color: #fff !important;
  padding: 0.188rem 0.625rem;
  border-radius: 0.75rem;
}
.whitePurpleTheme .roleType.Deactive{
  background: #dc3545 !important;
}

.tooltip-right.tooltip-light .tooltip-arrow {
  border: 0.063rem solid #1f1547 !important;
}

.tooltip-right.tooltip-light .tooltip-arrow::after {
  top: -0.135rem !important;
  right: 0.0625rem !important;
}

.tooltip-right.tooltip-light .tooltip-arrow {
  border-bottom: 0.063rem solid #1f1547 !important;
  border-right: 0.063rem solid #1f1547 !important;
}

.tooltip-bottom.tooltip-light .tooltip-arrow {
  border-bottom: 0.063rem solid #1f1547 !important;
  border-left: 0.063rem solid #1f1547 !important;
}

.whitePurpleTheme .mat-tab-label,
.whitePurpleTheme .mat-tab-link {
  color: #1f1547 !important;
  text-transform: capitalize;
}

button.timepicker-dial__item_active {
  background: #a2d45e !important;
}

.whitePurpleTheme p {
  hyphens: none !important;
}

.timepicker-button {
  line-height: 2.125rem !important;
  height: 2.75rem !important;
}

.whitePurpleTheme span.fs-20 h1,
.span.fs-20 h1 {
  font-size: 1.25rem !important;
  font-weight: 700 !important;
}
.whitePurpleTheme .mat-menu-panel,
.whitePurpleTheme .mat-dialog-popup-profile {
  overflow-x: hidden;
}

.whitePurpleTheme .mat-tab-label {
  height: 3rem !important;
  padding: 0 1rem !important;
  min-width: 10rem !important;
}

.whitePurpleTheme .mat-menu-panel {
  min-width: 8rem !important;
  max-width: 40rem !important;
}

.whitePurpleTheme .mat-menu-panel .mat-menu-content:not(:empty) {
  padding-bottom: 0rem !important;padding-top:0rem !important;
}

.whitePurpleTheme .mat-menu-panel.curator-side-menu .mat-menu-content {
  padding: 0rem !important;
}

.whitePurpleTheme .mat-menu-panel.curator-side-menu {
  width: 10rem !important;
  min-width: 10rem !important;
}

.border-circle .mat-tab-list .mat-tab-labels,
.mat-tab-group .hidden-details .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels {
  border: 0px solid #fff !important;
}

.mat-tab-group .hidden-details .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels,
.border-community .mat-tab-list .mat-tab-labels {
  border: 0px solid #fff !important;
}

.whitePurpleTheme .card2-style-height-with-hr-heading::-webkit-scrollbar {
  width: 0.313rem !important;
}

.whitePurpleTheme .card2-style-height-with-hr-heading::-webkit-scrollbar-thumb {
  background: #c2c5c5;
}


/* New card design for explorer and guide */

/* card style new */

.card-explorer .img_section_mirror::before,
.card-explorer .archive_img,
.card-explorer .img_section_mirror,
.card-explorer .card.archive_card {
  height: 25rem !important;
  border: 0rem !important;
  filter: drop-shadow(0rem 0.05rem 0.1rem rgba(0, 0, 0, 0.25));
  @media screen and (min-width: 1024px) and (max-width: 1368px) {
    height: 20rem !important;
  }
}

.card-explorer .card.archive_card {
  border: none !important;
  background: transparent !important;
}

.card-explorer .title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.whitePurpleTheme .card-explorer .img_section_mirror {
  position: absolute !important;
  width: 100%;
  margin-top: -0.6rem;
  margin-left: -0.56rem;
}

.card-explorer .img_section_mirror::before {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40.5%, rgba(0, 0, 0, 0.9) 100%);
  border-radius: 0.625rem !important;
}

.card-explorer .archive_img {
  cursor: pointer;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100% !important;
  left: 0;
  top: 0;
  border-radius: 0.625rem !important;
  z-index: 1;
  margin-top: 0 !important;
}

.card-explorer .title .member_card_name {
  margin: 0.625rem !important;
  margin-top: 0rem !important;
  margin-top: 0rem !important;
  padding-left: 0.5rem !important;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.card-explorer .title * {
  text-align: left !important;
  color: #fff !important;
}

.card-explorer:empty {
  display: none !important;
}

.card-explorer .title h3 {
  color: #d1d1d1 !important;
  font-weight: 300 !important;
}

.whitePurpleTheme .card-explorer .dotes-member-ul {
  position: relative !important;
  left: 1.25rem !important;
  bottom: 0.5rem !important;
  padding: 0 !important;
  list-style: none !important;
  display: block !important;
  min-height: 1.5rem !important;
}

.whitePurpleTheme .card-explorer .dotes-member-ul.role li {
  background-color: #d8d3ff;
  color: #1f1547 !important;
  padding: 0.5rem 0.75rem;
  border-radius: 1.5rem;
  font-weight: 800 !important;
  font-size: 1.125rem !important;
  line-height: 1.065rem;
  letter-spacing: 0.02em;
  color: #68478D;
  margin-bottom: 0.25rem;
  width:fit-content
}

.card-guide-row,
.card-explorer-row {
  margin: 0rem !important;
}

.card-explorer,
.card-guide {
  margin: 0.5rem 0rem
}

.card-guide .img_section_mirror::before,
.card-guide .archive_img,
.card-guide .img_section_mirror,
.card-guide .card.archive_card {
  height: 25rem !important;
  border: 0rem !important;
  filter: drop-shadow(0rem 0.05rem 0.1rem rgba(0, 0, 0, 0.25));
  @media screen and (min-width: 1024px) and (max-width: 1368px) {
    height: 20rem !important;
  }
}

.card-guide .card.archive_card {
  border: none !important;
  background: transparent !important;
}

.card-guide .title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.whitePurpleTheme .card-guide .img_section_mirror {
  position: absolute !important;
  width: 100%;
  margin-top: -0.6rem;
  margin-left: -0.65rem;
  background: transparent !important;
}

.card-guide .img_section_mirror::before {
  content: "";
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0) 40.5%,
      rgba(0, 0, 0, 0.9) 100%);
  border-radius: 0.625rem !important;
}

.card-guide .archive_img {
  cursor: pointer;
  border: 0.03125rem solid rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100% !important;
  left: 0;
  top: 0;
  border-radius: 0.625rem !important;
  z-index: 1;
  margin-top: 0 !important;
}

.card-guide .title .member_card_name {
  margin: 0.625rem 1rem !important;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  margin-bottom: 1rem !important;
}

.card-guide .title * {
  text-align: left !important;
  color: #fff !important;
}

.card-guide:empty {
  display: none !important;
}

.card-guide .title h3 {
  color: #d1d1d1 !important;
  font-weight: 300 !important;
}

.whitePurpleTheme .card-guide .dotes-member-ul {
  position: relative !important;
  left: 0.625rem !important;
  bottom: 0.5rem !important;
  padding: 0 !important;
  list-style: none !important;
  display: block !important;
}

.whitePurpleTheme .card-guide .dotes-member-ul.role li {
  background-color: #d8d3ff;
  color: #1f1547 !important;
  padding: 0.188rem 0.938rem;
  border-radius: 12.5rem;
  font-weight: 700 !important;
  margin-bottom: 0.25rem;
  width:fit-content
}

.whitePurpleTheme .card-guide .audio-guide-note {
  background: #fff !important;
  border-radius: 0.25rem;
  width: 91%;
}

.whitePurpleTheme .card-guide audio {
  background: #fff !important;
  opacity: 1;
  width: calc(100% - 1.85rem);
  padding-top: 0.5rem !important;
}

.whitePurpleTheme .card-guide audio::-webkit-media-controls-mute-button {
  display: none !important;
}

.card-guide .title h1,
.card-explorer .title h1 {
  font-family: 'Lato';  text-align: left !important;

  font-style: normal;
  font-weight: 700 !important;
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.01em;
  color: #FFFFFF !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
}
.card-guide .title h3,
.card-explorer .title h3 {padding-right:0.313rem !important;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 1.3125rem !important;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8) !important;
  flex: none;
  order: 1;
  flex-grow: 0;
  @media screen and (max-width:1200px) {padding-right:0.4rem !important;}
}

.card-guide .title h4,
.card-explorer .title h4 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 1rem !important;
  line-height: 1.188rem !important;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 2;
  padding: 0rem !important;
  flex-grow: 0;
  padding-right: 0.625rem !important;
}

.card-explorer .title h4 p,
.card-explorer .title h4 label {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 1rem !important;
  line-height: 1.188rem !important;
  letter-spacing: 0.02em;
  color: #FFFFFF !important;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.card-explorer .comma:not(:empty):not(:last-child):after {
  content: ", ";
}

.card-explorer .comma:last-child::after {
  content: ".";
}

.card-explorer .title h4 span {
  color: rgba(255, 255, 255, 0.8) !important;
  font-weight: 300 !important;
  font-size: 1.0625rem !important;
}

.card-guide .title .status-text,
.card-guide .title .avilability {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  font-size: 1rem !important;
  line-height: 1.188rem !important;
  letter-spacing: 0.02em;

  color: #FFFFFF;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.card-explorer .search-explorer .text_on_image h4 {
  padding: 0rem !important;

}

.whitePurpleTheme .card2-style-height-with-tabs-hr-heading .row {
  margin: 0rem !important;
}

.whitePurpleTheme .card-explorer .card.archive_card:hover {
  box-shadow: none !important
}

mat-icon {
  cursor: pointer !important;
}

.fa {
  cursor: pointer !important;
}

.listofdata {
  padding-left: 0.313rem !important;
}

.listofdata {
  position: relative;
  width: 91%;
  min-height: 3rem;
  display: flex;
  align-items: center;
  z-index: 9;
  flex-wrap: wrap;
  background: #d2d0da;
  border-radius: 0.188rem !important;
}

.host-add-button {
  margin-bottom: 1px;
}

.listofdata>div {
  margin-right: 0.625rem;
  margin-bottom: 0.313rem;
}

.listofdata>div i.fa-close {
  font-size: 9px;
  font-weight: 500;
  margin-right: 5px;
  margin-left: 5px;
  background: #a2d45e;
  border-radius: 50%;
  line-height: 1;
  width: 10px;
  height: 10px;
  text-align: center;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.btn.begin_quiz.linkButton {
  height: 30px;
  margin-bottom: 8px;
}

.whitePurpleTheme .url mat-form-field.url-field i.fa.fa-check {
  color: #61a30d !important;
  position: absolute;
  z-index: 9999;
  right: 7px;
  top: 20px;
}

.linkButton.disabled-Link {
  pointer-events: none;
  opacity: 0.5;
}

.mat-form-field-flex{
  align-items: center !important;
}
.mat-form-field-flex mat-datepicker-toggle.mat-datepicker-toggle {
  margin-top: 1.55rem !important;
display: block !important;
}

.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon{
  width: 1.5rem !important;
}
@media screen and (max-width:1200px) {
  .mat-form-field-flex mat-datepicker-toggle.mat-datepicker-toggle {
    margin-top: .55rem !important;
  }.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon{
    width: .55rem !important;
    transform: scale(2);margin-top: 1rem !important;
  }
}

.mat-menu-search {
  padding:0.625rem 0 !important;
}
.mat-menu-search .row {
  align-items: center !important;
}
.mat-menu-search .row select.memb-select{
  padding: 0.75rem !important;
  border: none !important;
  background: #d2d0da !important;border-radius: 0rem !important;
  margin-top:0rem !important;
  }
  
  .mat-input-element:disabled,.mobile-pad:disabled,
  input[type="text"]:disabled { 
    background : #e0dee5 !important;
  }
    .mat-menu-search .row input.memb-select{
    border: none !important;
  }
  .ngx-select__toggle.btn.form-control{border: 0px solid #d2d0da !important;}
   input.ngx-select__search.form-control::placeholder{color:$color !important;}
    input.ngx-select__search.form-control:focus{color:$color !important;}
    .register-c input.ngx-select__search.form-control{background: none !important;}
    .register-c input.ngx-select__search.form-control:focus{border:none !important;box-shadow: none !important;}
    .ngx-select__item_active{
      color:#fff !important;
    }

    /* Test website on real Safari 11+ */

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .whitePurpleTheme .timepicker-dial__item_active {
      color: #1f1547 !important;
      border: 2px solid #1f1547 !important;
      outline-color: #1f1547 !important;
    }
    .mat-menu-search .row input.memb-select {
      height: 2.5rem !important;
    }
    span.pos-relative.top-5 {
      top: -0.35rem !important;
    }

    .new-view-dialog-detail .row .col-md-7 p.pt-0.mb-0 span.icon-info {
      top: 0.25rem !important;
    }

    .new-view-dialog-detail .row .col-md-7 p.pt-0.mb-0 {
      margin-top: -1rem !important;
    }

    .add-request
      .example-radio-group.mt-0
      .radioBtnClass.commonFontClass
      .mat-radio-label
      .mat-radio-label-content {
      margin-top: -0.9rem !important;
    }

    .add-request
      .example-radio-group.mt-0
      .radioBtnClass.commonFontClass:first-child
      .mat-radio-label
      .mat-radio-label-content {
      margin-top: -2.4rem !important;
    }
    .add-request span.icon-info {
      top: 0.3rem !important;
    }
    .whitePurpleTheme .timepicker-dial__item_active {
      color: #1f1547 !important;
      border: 2px solid #1f1547 !important;
      outline-color: #1f1547 !important;
    }
  }
}
/* Test website on real Safari 11+ */

/* Safari 10.1 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    .add-request
      .example-radio-group.mt-0
      .radioBtnClass.commonFontClass
      .mat-radio-label
      .mat-radio-label-content {
      margin-top: -0.9rem !important;
    }

    .add-request
      .example-radio-group.mt-0
      .radioBtnClass.commonFontClass:first-child
      .mat-radio-label
      .mat-radio-label-content {
      margin-top: -2.4rem !important;
    }
    .add-request span.icon-info {
      top: 0.3rem !important;
    }
    span.pos-relative.top-5 {
      top: -0.35rem !important;
    }

    .new-view-dialog-detail .row .col-md-7 p.pt-0.mb-0 span.icon-info {
      top: 0.25rem !important;
    }
    .new-view-dialog-detail .row .col-md-7 p.pt-0.mb-0 {
      margin-top: -1rem !important;
    }

    .whitePurpleTheme .timepicker-dial__item_active {
      color: #1f1547 !important;
      border: 2px solid #1f1547 !important;
      outline-color: #1f1547 !important;
    }
    .whitePurpleTheme .timepicker-dial__item_active {
      color: #1f1547 !important;
      border: 2px solid #1f1547 !important;
      outline-color: #1f1547 !important;
    }

    .mat-menu-search .row input.memb-select {
      height: 2.5rem !important;
    }
  }
}
@-moz-document url-prefix() {
  * {
    scrollbar-width: none; /* Firefox implementation */
  }

  .mat-menu-search .row input.memb-select {
    height: 2.5rem !important;
  }
}
.spa-btn-change_new_img {
  border: none !important;
}
.mat-dialog-container {
  display: inline-table !important;
}

.cdk-virtual-scroll-content-wrapper {
  position: inherit !important;
}
.mat-radio-label-content {
  padding-top: 0.125rem !important;
  padding-left: 0.313rem !important;
}

.profilActions .myProdileMenu {
  margin-top: 0;
}
/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .whitePurpleTheme .timepicker-dial__item_active {
      color: #1f1547 !important;
      border: 2px solid #1f1547 !important;
      outline-color: #1f1547 !important;
    }

   .mat-menu-search .row input.memb-select {
      height: 2.5rem;
    }

    span.pos-relative.top-5 {
      top: -0.35rem;
    }

    .whitePurpleTheme .timepicker-dial__item_active {
      color: #1f1547 !important;
      border: 2px solid #1f1547 !important;
      outline-color: #1f1547 !important;
    }
  }
}
/* material time picker Styles */
.whitePurpleTheme ngx-material-timepicker-toggle {
  position: absolute;
  right: 0;
  color: #fff;
}
.whitePurpleTheme ngx-material-timepicker-toggle * {
  color: #fff !important;
}

.whitePurpleTheme .timepicker__header {
  background: #fff !important;
  border-bottom: 0.063rem solid #1f1547;
}

.whitePurpleTheme .timepicker__body .whitePurpleTheme .clock-face__number>span.active {
  background: #67468e !important;
}

.whitePurpleTheme .clock-hand {
  background: #67468e !important;
  color: #67468e !important;
  border-color: #67468e !important;
}

.whitePurpleTheme .clock-face {
  background: #69488e !important;
  border: 0.063rem solid rgba(255, 255, 255, 0.1);
}

.whitePurpleTheme .timepicker-button {
  background: #a2d45e !important;
  margin-left: 0.313rem;
}

.whitePurpleTheme .timepicker-button:hover {
  background: #69488e !important;
  color: #fff !important;
}

.whitePurpleTheme .clock-face__number>span,
.whitePurpleTheme .clock-face__number--outer>span {
  background-color: transparent !important;
}

.whitePurpleTheme .clock-face :not(.clock-face__number, .clock-face__number--outer span) {
  background: #fff !important;
}

.whitePurpleTheme .clock-face__number--outer>span {
  color: #fff !important;
}

.whitePurpleTheme .timepicker-dial__time {
  color: #1f1547 !important;
}

.timepicker-dial__control {
  width: 5rem !important;
  font-size: 3.125rem !important;
}

input.timepicker-dial__control:focus-visible {
  outline-color: #1f1547 !important;
}

.whitePurpleTheme .timepicker-dial__item_active,
.whitePurpleTheme .timepicker-dial__item,
.whitePurpleTheme .timepicker-dial__item_active,
.whitePurpleTheme .timepicker-dial__item {
  color: #1f1547 !important;
  outline-color: #1f1547 !important;
}

.whitePurpleTheme .timepicker-button>span.active {
  color: #1f1547 !important;
}

.whitePurpleTheme .timepicker-button {
  color: #1f1547 !important;
}

.whitePurpleTheme .timepicker__body {
  background: #fff !important;
  border-bottom: 0.063rem solid #1f1547;
}

.whitePurpleTheme .timepicker__actions {
  background: #fff !important;
}

.whitePurpleTheme .clock-face__number>span.disabled {
  color: #1f1547 !important;
}

.timepicker__actions div:first-child .timepicker-button {
  background: grey !important;
  color: #fff !important;
  border: 0.063rem solid #fff !important;
}

.timepicker__actions div:first-child .timepicker-button:hover {
  background: #67468e !important;
  color: #fff !important;
  border: 0.063rem solid #67468e !important;
}

.timepicker__actions div:last-child .timepicker-button {
  color: #67468e !important;
  border: 0.063rem solid #67468e !important;
}

.timepicker__actions div:last-child .timepicker-button:hover {
  background-color: #67468e !important;
  color: #fff !important;
  border: 0.063rem solid #67468e !important;
}

.timepicker__actions div:last-child {
  order: 1;
}

.timepicker__actions div:first-child {
  order: 2;
}
/* topbar-dropdown */
.whitePurpleTheme .topbar-dropdown-bg-color {
  background: #fff !important;
}

.whitePurpleTheme .new-dropdown-menu * {
  color: #1f1547 !important;
}

.whitePurpleTheme .mat-expansion-indicator::after {
  color: #1f1547 !important;
}

.whitePurpleTheme .new-arrow {
  background: #fff !important;
  display: none !important;
}

/* notifications */
.whitePurpleTheme .noti-submenu-title {
  color: #1f1547 !important;
}

/* material Calender Styles */
svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
  color: rgb(255, 255, 255, 1);
}

.dialogF.viewP svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
  cursor: auto !important;
}

.mat-datepicker-content {
  display: block;
  border-radius: 1.25rem !important;
  background: rgba(52, 58, 94, 1) !important;
  color: rgb(255, 255, 255, 1) !important;
}
.mat-calendar-body-selected {
  background-color: #ed7422 !important;
  color: rgb(255, 255, 255, 1) !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
  background-color: #ed7422 !important;
}

.mat-calendar-body-cell-content {
  color: rgb(255, 255, 255, 1) !important;
  border-color: transparent;
  position: absolute;
  top: 5%;
  left: 5%;
  font-family: lato;
}
.mat-calendar-body-label {
  color: rgb(255, 255, 255, 1) !important;
}

.mat-calendar-table-header th {
  text-align: center;
  padding: 0 0 0.5rem 0;
  color: rgb(255, 255, 255, 1) !important;
}

button.mat-calendar-period-button.mat-button.mat-button-base {
  border: 0.063rem solid rgb(255, 255, 255, 1) !important;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgb(255, 255, 255, 1) !important;
}

.mat-calendar-arrow {
  border-top-color: rgb(255, 255, 255, 1) !important;
}
/* Admin part end  */

.curator_card_main {
  background: transparent;
  position: relative;
}

.curator_card_main .new-c-photo {
  border-radius: 0.313rem;
}

.curator_card_main .new-c-photo img {
  width: 5rem;
  height: 5rem;
  border-radius: 0.313rem;
}


.curator_card_main .new-crd-titkle h3 {
  text-align: left;
  text-transform: capitalize;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.curator_card_main .new-crd-titkle h4 {
  text-align: right;
  text-transform: capitalize;
  margin: 0;
}

.curator_card_main .new-crd-titkle p.curat_descript {
  margin: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-top: 0.625rem;
  padding-bottom: 0.313rem;
}

.curator_card_main p.new-card-para {
  line-height: 1rem;
  margin: 0;
  padding: 0.188rem 0 0.125rem 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.curator-side-menu {
  margin-top: 0.938rem;
  margin-right: -1.25rem;
}

.curator-side-menu::after {
  right: 0.125rem;
  top: -0.375rem;
  left: auto;
}

.curator-side-menu::before {
  top: 0.688rem;
  right: 0;
  left: auto;
}
.manage-cummunity-eye-menu {
  margin-top: 1rem;
  margin-right: -0.938rem;
}

.manage-cummunity-eye-menu:after {
  right: 0rem;
  top: -0.375rem;
  left: auto;
}

.manage-cummunity-eye-menu::before {
  top: -0.75rem;
  right: -0.125rem;
  left: auto;
}

input.my_search_input {
  width: 100%;
  outline: none;
}
span.spa-btn-change{
  background: transparent;
  border-radius: 50%;
  height: 1.563rem;
  width: 1.563rem;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.spa-btn-change-icn {
  margin: 0 0.875rem 0 0.313rem;
  border-radius: 50%;
  height: 1.563rem;
  width: 1.563rem;
  position: relative;
  cursor: pointer;
  display: flex;
}

span.spa-btn-change .mat-icon{
  position: relative;
  top: 3px;
  margin-right:0rem !important;
}
.spa-btn-change-icn img {
  width: 100%;
  margin-left: 0.188rem;
}
.spa-btn-change_new_img img {
  width: 100%;
  border-radius: 0rem;
}

span.border-linespa {
  position: relative;
  display: block;
}

.mem-radio-btn {
  margin-right: 0.938rem;
  margin-top: 0.125rem;
  margin-bottom: 0;
}

.memb-select {
  width: 6.25rem !important;
  margin-top: 0.313rem !important;
  border: none !important;
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
  float: right;
  margin-right: 0.938rem !important;
}

.memb-select-float {
  margin-right: 0 !important;
}

.memb-select option {
  color: rgba(255, 255, 255, 1);
  background: rgba(65, 58, 98, 1) !important;
}

.memg-btn-go .begin_quiz {
  float: right;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: rgba(162, 212, 94, 1);
}

.memg-btn-go {
  display: block;
  height: auto;
  color: rgb(255, 255, 255, 1);
  padding: 0rem 0.938rem 0rem 1.063rem;
  margin-bottom: 0.313rem;

  @media screen and (max-width: 767px) {
    padding: 0rem 0.938rem 0rem 0.063rem;
  }
}

.tooltip-member {
  position: absolute;
  right: 0;
  bottom: 0;
}

.dotes-member-ul li {
  margin-right: 0.188rem;
}

.memb-small-dec {
  display: block;
  margin-bottom: 1.25rem;
}

mat-radio-button.mem-radio-btn .mat-radio-label {
  margin-left: 0.938rem;
}



.scroll-gap {
  padding-right: 0.313rem;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: auto !important;
  display: block !important;
}

/* end admin */
/* curator part end  */

.select2-container--default .select2-selection--single {
  color: #ffffff;
  background-color: #343a5e ;
  border: 0rem solid #3b3963 ;
  border-bottom: 0.031rem solid #ffffff87 ;
  border-radius: 0rem ;
  height: 2.563rem ;
}

.select2-container--default .select2-selection--single:focus {
  color: #ffffff;
  background-color: #343a5e ;
  border: 0rem solid #3b3963 ;
  border-bottom: 0.031rem solid #ffffff ;
  border-radius: 0rem ;
}

.select2-container {
  width: auto ;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #ffffff ;
  line-height: 2.5rem ;
}

select.mat-input-element {
  color: #ffffff80 !important;
}

.form-control textarea {
  font-size: 1rem;
}

.select-change .select2-container {
  width: 100% ;
}

textarea.form-control {
  height: auto;
  resize: none;
}

.selct-width {
  width: 100% ;
}

textarea.extra-textarea {
  width: 100%;
}
a {
  width: 100%;
  font-weight: 500;
}
input[type="file"] {
  display: none;
}
.scroll-hide {
  min-height: auto ;
  max-height: none ;
}

.select2-container--open .select2-dropdown {
  left: 0;
  width: 15.625rem ;
}

.cdk-overlay-pane {
  height: auto ;
}

.mat-dialog-container {
  display: inline-table ;
}

.cdk-virtual-scroll-content-wrapper {
  position: inherit ;
}

.preload-btn {
  width: 1.25rem;
  width: 1.25rem;
  margin: 0rem auto;
  position: absolute;
  left: 0;
  right: 0;
}

mat-radio-button {
  margin-top: 1.25rem;
}

mat-radio-button .mat-radio-label {
  margin-bottom: 0.938rem;
}

mat-radio-button.mem-radio-btn .mat-radio-label {
  margin-left: 0.938rem !important;
  margin-bottom: 0rem !important;
  color: #ffffff;
  padding-bottom: 0.438rem;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #a2d45e !important;
}

.member_card_name {
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  letter-spacing: 0.063rem;
  cursor: pointer;
  text-transform: capitalize;
}


.scroll_class_list {
  height: 62vh ;
  scrollbar-color: #c5c5c5;
  scrollbar-width: thin;
  padding-right: 0.75rem;
  overflow-x:hidden;
  overflow-y: auto;
}


.stuff_right {
  float: right;
  padding: 0.313rem;
}

.bg-color-transp {
  background: transparent !important;
  border: 0.063rem solid none !important;
}

.border-btm-search {
  border-top: 0.063rem solid #f0f8ff4d;

  padding-top: 1.25rem !important;
}

img.img_radius_none {
  border-radius: 0rem;
  cursor: pointer;
}

.ng-select .ng-select-container {
  color: #ffffff80;
  border-bottom: 0.031rem solid #ffffff80;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #ffffff80 !important;
}

.curator_pop_form::-webkit-scrollbar {
  width: 0.313rem;
}

.curator_pop_form::-webkit-scrollbar-track {
  background: none;
}

.curator_pop_form::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 0.375rem;
  border: 0.188rem solid #c5c5c5;
}

.community-box {
  background: transparent;
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
  text-align: center;
  padding: 0.313rem 0.375rem;
  border: 0.125rem solid #67468e;
}

.browse-img-community {
  height: 10.625rem;
  width: 100%;
  border-radius: 0.625rem;
  cursor: pointer;
}

.browse-img-community img {
  display: none;
}

.browse-img-community-inpt {
  opacity: 0;
}

.curator_img {
  width: 12.5rem;
  height: 13.5rem;
  border-radius: 0.75rem;
  max-width: 100%;
}

.curator-createimg {
  border-radius: 50% !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  object-fit: cover;
}

.curator_pop_form {
  overflow: auto;
  overflow-x: hidden;
  padding: 0rem 1.563rem 0 0;
  height: calc(100vh - 15.625rem) !important;
  margin-bottom: 0.625rem;
  scrollbar-color: #c5c5c5;
  scrollbar-width: thin;
}

.curator_pop_form_change {
  width: 100%;
  overflow: hidden;
}

.selec-curator-width {
  width: 100% !important;
}

.curator_pop_form_chng .row .col-sm-3 div {
  margin-top: 1.25rem;
}

.curator_pop_form_chng .row .col-sm-3 div img {
  width: 100% !important;
  height: 9.688rem !important;
  object-fit: cover;
}

.curator_pop_form .row .col-sm-3 div {
  margin-top: 1.25rem;
}

.cmn_curat_img {
  float: left;
  margin-left: 0.625rem;
}

.cmn_curat_txt {
  float: left;
  margin-left: 1.25rem;
  width: 90%;
}

.cu_community {
  float: left;
}

.cu_community_date {
  float: right;
}

.curat_decri_dat_set {
  overflow: hidden;
}

.new_card_01 {
  padding: 1.25rem 1.25rem;
  border-radius: 0.75rem;
  position: relative;
  border: 0.031rem solid #67468e;
}

.new_card_01 .new-c-photo {
  border-radius: 0.313rem;
}

.help-desk {
  padding: 0.625rem !important;
  padding-bottom: 0.0625rem !important;
  padding-top: 0.0625rem !important;
}

.new_card_01 .new-c-photo img {
  width: 5rem;
  height: 5rem;
  border-radius: 0.313rem;
}

.new_card_01 .new-crd-titkle h3 {
  text-align: left;
  text-transform: capitalize;
  margin: 0;
}

.new_card_01 .new-crd-titkle h4 {
  text-align: right;
  text-transform: capitalize;
  margin: 0;
}

.new_card_01 p.new-card-para {
  line-height: 1rem;
  margin: 0;
  padding: 0.188rem 0 0.125rem 0;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.new_card_01 .new-crd-titkle h5 {
  padding-top: 0.625rem;
  margin: 0;
}

.new_card_01 .new-crd-titkle h5 span.admin-profi {
  margin-right: 0.313rem;
}

.new_card_01 .new-crd-titkle h5 span.admin-profi img {
  border-radius: 0.625rem;
}

.new_card_01 span.new-card-span {
  position: absolute;
  right: 0.625rem;
  bottom: 0;
  cursor: pointer;
}

.new_card_01 span.new-card-span .fa {
  vertical-align: middle;
  margin-left: 0.313rem;
}

.card_num_02 {
  cursor: pointer;
  background: #1f2f5d;
  color: #ffffff;
  padding: 1.875rem 0.625rem;
  border-radius: 0.938rem;
  border: none;
  margin: 0.313rem 0rem;
  cursor: pointer;
  position: relative;
}

.card_num_02 img {
  width: 4.375rem;
  height: 4.375rem;
  border-radius: 50%;
}

.card_num_02 p {
  padding-left: 0.938rem;
  margin: 0;
  line-height: 1.125rem;
}

.card_num_02 .card_n2_ul {
  position: absolute;
  right: 0.625rem;
  bottom: -0.313rem;
}

.card_num_02 .card_n2_ul li {
  float: left;
  margin: 0 0.125rem;
}

.card_num_02 .card_n2_ul li span.card_n2 {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #5b9bd5;
  display: inline-block;
}

.card_num_02 .card_n2_ul li:nth-child(2) span.card_n2 {
  background: #ffd966;
}

.card_num_02 .card_n2_ul li:nth-child(3) span.card_n2 {
  background: #ff0000;
}

.card_num_02 .card_03_sub {
  position: absolute;
  left: 0.625rem;
  bottom: 0.5rem;
}

.card_num_02 .card_03_sub2 {
  position: absolute;
  right: 0.625rem;
  bottom: 0.5rem;
}



.past_activity_table::-webkit-scrollbar {
  width: 0.313rem;
}

.past_activity_table::-webkit-scrollbar-track {
  background: none;
}

.past_activity_table::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 0.375rem;
  border: 0.188rem solid #c5c5c5;
}

.past_activity_table {
  overflow: auto;
  overflow-x: hidden;
  padding: 0rem 0.313rem 0 0;
  height: 50vh;
  display: block;
}

.ng-select-multiple.ng-select-searchable.ng-select-clearable.ng-select.ng-valid.ng-touched.ng-select-bottom.ng-dirty .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 1.5rem !important;
}

.ng-select-multiple.ng-select-searchable.ng-select-clearable.ng-select.ng-select-top.ng-touched.ng-dirty.ng-valid .ng-select-container.ng-has-value .ng-value-container .ng-placeholder,
.meaningsphere_hosts.ng-select-multiple.ng-select-searchable.ng-select-clearable.ng-select .ng-select-container.ng-has-value .ng-value-container .ng-placeholder,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-valuecontainer .ng-placeholder {
  font-size: 1.5rem !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 1.125rem !important;
  padding-left: 0.313rem !important;
  padding-top: 0.65rem;
}

.ng-select-multiple.ng-select-opened .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 1.5rem !important;
}

.ng-dropdown-panel {
  background: #ffffff;
  overflow: auto;
  overflow-x: hidden;
  padding: 0rem 0.313rem 0 0;
  overflow: -moz-scrollbars-none;
}

.ng-dropdown-panel .scroll-host::-webkit-scrollbar {
  width: 0.313rem;
}

.ng-dropdown-panel::-webkit-scrollbar-track {
  background: none;
}

.ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 0.375rem;
  border: 0.188rem solid #c5c5c5;
}

.ng-dropdown-panel::-moz-scrollbar {
  display: none !important;
  width: 0;
  scrollbar-width: none;
  overflow: auto;
  overflow: -moz-scrollbars-none;
}
.insightMatTabs .mat-tab-header {
  border-bottom: none;
}

.insightMatTabs .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels {
  flex-direction: column !important;
}

.insightMatTabs .mat-tab-body-wrapper {
  width: calc(100% - 10rem);
  border: 0.063rem solid #3e668f !important;
  border-radius: 0.625rem;
}

.insightMatTabs .mat-tab-body-content {
  padding: 0.625rem !important;
}

.insightMatTabs .mat-tab-label {
  justify-content: start;
  position: relative;
  margin-bottom: 0.313rem;
}

.insightMatTabs .mat-tab-label .mat-tab-label-content {
  width: 100%;
  justify-content: space-between;
}

.insightMatTabs .mat-tab-label button {
  margin: 0;
  padding: 0;
}

.insightMatTabs .mat-ink-bar {
  display: none !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0.75em 0rem 0 0 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  background-color: transparent;
  font-family: "Lato";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  color: #1f1547;
}

button.btn-addnew {
  text-align: right !important;
  float: right !important;
  margin-right: 0.938rem !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 0.063rem solid #f0f8ff0f !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent !important;
}

.card.archive_card_new {
  min-height: 6.25rem !important;
}

.archive_card_new h5.date-arcvh-card {
  position: absolute;
  right: 0.938rem;
  top: 0;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0;
}

.archive_card_new p.para-arch-card {
  margin: 0;
  line-height: 1.375rem;
  padding-bottom: 0.313rem;
}

.archive_card_new .new-c-photo {
  border-radius: 0.313rem !important;
}

.archive_card_new p.category-archiv-card {
  line-height: 1rem;
  margin: 0;
}

ul.rate-area {
  padding: 0;
}

.add-view-btn {
  float: right;
  margin-right: 0.938rem !important;
}



.spa-btn-change1 {
  float: right !important;
  margin-top: -1.25rem !important;
  margin-right: -1.5rem !important;
}

.activityStats canvas {
  background-color: transparent;
  border-radius: 0.75rem;
  color: white;
}

span img.drop-img-set {
  width: 1.563rem;
  margin-right: 0.188rem;
}

.forth-diy-text {
  height: 6.25rem !important;
}

.prof_list_title {
  color: #fff;
}

.swal2-styled.swal2-confirm {
  border-radius: 0.313rem !important;
  background-color: #a3d65c !important;
  color: #212529 !important;
  text-transform: uppercase;
  transition: all 0.5s;
  padding: 0.313rem 0.625rem !important;
}

.swal2-styled.swal2-confirm:hover {
  background: #69488e !important;
  color: #ffffff !important;
}

.swal2-styled.swal2-cancel {
  border-radius: 0.313rem;
  background-color: #757575;
  color: #69488e !important;
  text-transform: uppercase;
  transition: all 0.5s;
  padding: 0.313rem 0.5rem !important;
}

.swal2-styled.swal2-cancel:hover {
  background: #69488e !important;
  color: #ffffff !important;
}

.swal2-popup .swal2-title {
  font-weight: 500;
  font-size: 1.3rem;
  margin: 2rem 0 0 0;
  text-transform: uppercase;
}

.new-card-span .img_radius_none {
  width: 1.563rem;
  height: 1.563rem;
  position: absolute;
  right: 1.062rem;
  bottom: 0;
}

.view-community-tr img {
  margin-top: 0.438rem;
}

.curator_card_main_padd {
  padding: 1.25rem 1.25rem 0.625rem 1.25rem !important;
}

.digit-view-community {
  margin-top: 0.25rem;
  display: block;
}

.profile-editing-pad {
  position: relative;
}

.profile-edit-icn {
  position: absolute;
  right: -0.75rem;
  bottom: 1.438rem;
  z-index: 999999;
  cursor: pointer;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  background: #ffffff99;
  padding: 0.25rem;
  display: flex;
  vertical-align: middle;
  column-fill: balance;
  justify-content: center;
}

.profile-edit-icn-circle {
  right: 0.188rem !important;
  bottom: 1.438rem !important;
}

.profile-edit-icn-pop {
  right: 0.35rem !important;
  bottom: 0.75rem !important;
  padding: 0.125rem !important;
  padding-top: 0.35rem !important;
}

.profile_upload_icon {
  right: 0.625rem;
  bottom: 0.938rem;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.125rem !important;
  background-color: #ffffffe6 !important;
}

.profile-edit-icn img {
  width: 100% !important;
  padding: 0.125rem;
}

.curator_pop_form_chng .row .col-sm-3 div .profile-edit-icn img {
  width: 100% !important;
  height: auto !important;
}

.mat-tab-body-content {
  padding-top: 1.563rem !important;
}

.profile_scroll_curator::-webkit-scrollbar {
  width: 0.313rem;
}

.profile_scroll_curator::-webkit-scrollbar-track {
  background: none;
}

.profile_scroll_curator::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 0.375rem;
  border: 0.188rem solid #c5c5c5;
}

.profile_scroll_curator {
  overflow: auto;
  overflow-x: hidden;
  padding: 0rem 0.938rem 0 0;
  height: calc(100vh - 23.75rem) !important;
  scrollbar-color: #c5c5c5;
  scrollbar-width: thin;
  margin-bottom: 0.938rem;
}

.admin-profi img {
  border-radius: 50%;
}

mat-form-field input {
  padding: 0.625rem !important;
  font-weight: normal !important;
}

div.cdk-overlay-connected-position-bounding-box[style*="justify-content: flex-start"] .mat-menu-panel.search-menunw.mat-menu-connections::before {
  left: 8.75rem !important;
}

div.cdk-overlay-connected-position-bounding-box[style*="justify-content: flex-start"] .mat-menu-panel.search-menunw.mat-menu-connections::after {
  left: 8.875rem !important;
}

.mat-menu-panel.search-menunw.mat-menu-connections input {
  width: 100% !important;
  background: #ffffff66 !important;
}

.mat-menu-panel.search-menunw.mat-menu-connections img {
  border-radius: 0;
}

.mat-menu-panel.search-menunw .mat-menu-content::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  z-index: 99999;
}

.swal2-styled.swal2-cancel {
  color: #fff !important;
  font-family: "Lato";
}

.swal2-styled.swal2-confirm {
  color: #69488e;
  font-family: "Lato";
}

.img-sectoin-chng {
  margin: 0 auto;
}

.document-card {
  min-height: auto !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.archive_img_chng {
  margin-top: 0;
}

.title-doc-card h3 {
  text-align: center;
  padding: 0.625rem 0 0 0;
  text-transform: capitalize;
  margin: 0;
  line-height: 0.875rem;
}

.title-doc-card h3 span {
  text-align: center;
}

.title-doc-card h4 {
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding: 0.313rem 0;
}

.title-doc-card h5 {
  text-align: center;
  margin: 0;
}

.title-doc-card hr {
  margin-top: 0.5rem !important;
}

.tooltip-member-chng {
  bottom: -0.438rem;
}

.mat-form-field-infix {
  display: flex !important;
}

.note-editable {
  height: 3.125rem;
  width: 100%;
  text-align: initial !important;
}

.note-editing-area .note-editable {
  height: auto;
  width: 100%;
}

.survey-tab label {
  width: 12rem;
  text-align: left;
}

.survey-tab .mat-tab-label .mat-tab-label-content {
  text-transform: uppercase !important;
  line-height: 0.875rem;
}

.tab-subtitled .mat-tab-label .mat-tab-label-content {
  flex-wrap: wrap !important;
}

.tab-subtitled .mat-tab-label .mat-tab-label-content span {
  display: flex !important;
  flex: 1 0 100% !important;
  justify-content: center !important;
}

.survey-tab .tab-subtitled .mat-tab-label {
  width: 12rem;
  display: block !important;
}

.survey-tab .mat-tab-group[mat-stretch-tabs]>.mat-tab-header .mat-tab-label {
  width: 12rem !important;
  margin-right: 0.938rem;
  padding: 0.313rem 0.625rem !important;
}

.mat-card.survey-card {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin-top: 1.25rem;
}

.mat-card.survey-card .mat-tab-labels {
  display: inline-flex !important;
}

.mat-card.survey-card .mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none !important;
}

.mat-card.survey-card .mat-tab-body-content::-webkit-scrollbar {
  width: 0.313rem;
}

.mat-card.survey-card .mat-tab-body-content::-webkit-scrollbar-track {
  background: none;
}

.mat-card.survey-card .mat-tab-body-content::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 0.375rem;
  border: 0.188rem solid #c5c5c5;
}

.mat-card.survey-card .mat-tab-body-content {
  overflow: auto !important;
  overflow-x: hidden !important;
  height: 55vh !important;
  margin-top: 0;
  border-radius: 0.625rem;
  padding: 0.625rem 0 !important;
  margin-bottom: 0.625rem;
}

vg-player {
  margin: 0.5rem 0 !important;
}

.memb-select-padd {
  padding: 0 !important;
}

.bodyContent {
  display: grid !important;
  grid-gap: 0.313rem;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
}

.bodyContentGrid {
  grid-auto-rows: 0.313rem;
}


/* end Curator Space */
/* Mat menu dropdown CSS Starts */
.searchMenuClass .mat-menu-content {
  padding: 1rem !important;
}

.note-editor .note-editing-area .note-editable::-webkit-scrollbar {
  width: 0.313rem;
  cursor: pointer;
}

.note-editor .note-editing-area .note-editable::-webkit-scrollbar-track {
  background: none;
}

.note-editor .note-editing-area .note-editable::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 0.375rem;
  border: 0.188rem solid #c5c5c5;
}

.note-editor .note-editing-area .note-editable {
  overflow: auto !important;
  overflow-x: hidden !important;
  padding: 0.313rem 0.313rem 0.313rem 0.625rem !important;
  height: 25vh !important;
  font-family: "Lato" !important;
}

.curator_pop_form .row .col-sm-3 .user-pos-circle {
  width: 100% !important;
}

.mat-card-actions,
.mat-card-subtitle,
.mat-card-content {
  margin-bottom: 0 !important;
}

.scroll_class_list_mgcommunity .mat-expansion-panel-content {
  padding-bottom: 0.625rem !important;
}

.scroll_class_list_mgcommunity .btn.begin_quiz {
  float: right;
  margin-right: 0.625rem;
  margin-top: 0.313rem;
}

.scroll_class_list_mgcommunity table tr td span {
  display: inline-block;
}

.scroll_class_list_mgcommunity table tr td .fas {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  cursor: pointer;
  color: #fffefea8;
}

.scroll_class_list_mgcommunity table tr td.td-class {
  margin-right: 0.75rem;
  display: block;
}

.marg-form-remove .mat-form-field-wrapper {
  margin: 0 0.625rem !important;
}

.marg-form-summary .mat-form-field-wrapper {
  margin: 0 0rem !important;
}

.labelValueClass {
  padding-left: 1.25rem;
}

.scroll_class_about::-webkit-scrollbar {
  width: 0.313rem;
}

.scroll_class_about::-webkit-scrollbar-track {
  background: none;
}

.scroll_class_about::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 0.375rem;
  border: 0.188rem solid #c5c5c5;
}

.scroll_class_about {
  overflow: auto;
  overflow-x: hidden;
  padding: 0rem 0.313rem 0 0;
  height: calc(100% - 25.1rem) !important;
  scrollbar-color: #c5c5c5;
  scrollbar-width: thin;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #00000000 !important;
}

.icon.icon_pin img {
  width: 1.125rem;
  vertical-align: bottom;
}

.memg-btn-go .begin_quiz.begin_quiz-go {
  margin-bottom: 0;
  margin-top: 0.313rem;
}

/* Mat menu dropdown CSS Ends */
/* Media Queries */

@media only Screen and (max-width: 1690px) {
  .cmn_curat_txt {
    width: 88%;
  }
}

@media only Screen and (max-width: 1610px) {
  .scroll_class_about {
    height: 67vh;
  }
  .mat-card.survey-card .mat-tab-body-content {
    height: 46vh !important;
  }

  .profile_scroll_curator {
    height: calc(100vh - 21.875rem) !important;
    margin-bottom: 0.875rem;
  }

  .curator_pop_form_change {
    height: 72vh;
  }

  .scroll_class_list {
    height: 58vh;
  }

  .new-crd-titkle {
    padding-left: 0.938rem;
  }

  .cmn_curat_txt {
    width: 88%;
  }

  .card_num_02 img {
    width: 3.75rem;
    height: 3.75rem;
  }

  .card_num_02 p {
    padding-left: 0.813rem;
    margin: 0;
    line-height: 0.875rem;
  }

  .new_card_01 .new-c-photo img {
    width: 4.063rem;
    height: 4.063rem;
    margin: 0.438rem auto;
  }

}

@media only Screen and (max-width: 1450px) {
  .profile_scroll_curator {
    height: calc(100vh - 21.875rem) !important;
  }

  .note-editor .note-editing-area .note-editable {
    font-size: 1rem !important;
  }

  .curator_pop_form_change {
    height: 70vh;
  }

  .card_num_02 img {
    width: 3.438rem;
    height: 3.438rem;
  }

  .card_num_02 p {
    padding-left: 0.813rem;
    margin: 0;
    line-height: 0.875rem;
  }

  .new_card_01 .new-c-photo img {
    width: 3.75rem;
    height: 3.75rem;
  }


  .extra-textarea-div {
    width: 75%;
    margin-top: 1rem;
  }

  .cmn_curat_txt {
    width: 86%;
  }

  .survey-tab .mat-tab-label .mat-tab-label-content {
    line-height: 0.75rem;
  }

  .survey-tab .mat-tab-group[mat-stretch-tabs]>.mat-tab-header .mat-tab-label {
    padding: 0.25rem 0.625rem !important;
  }

  .mat-card.survey-card .mat-tab-body-content {
    height: 19vh !important;
  }
}

@media only Screen and (max-width: 1410px) {
  .profile_scroll_curator .padd-right {
    padding-right: 0;
  }

  .profile_scroll_curator .padd-left {
    padding-left: 0;
  }

  .img_section_mirror {
    margin: 0 auto;
  }

  .scroll_class_list {
    height: 64vh;
  }
}

@media only Screen and (max-width: 1370px) {
  .scroll_class_about {
    height: 61vh;
  }

  .note-editor .note-editing-area .note-editable {
    font-size: 1rem !important;
  }

  .mat-card.survey-card .mat-tab-body-content {
    height: 34vh !important;
  }
  .curator_pop_form_change {
    height: 90vh;
  }

  .card_num_02 img {
    width: 3.125rem;
    height: 3.125rem;
  }

  .card_num_02 p {
    padding-left: 0.813rem;
    margin: 0;
    line-height: 0.875rem;
  }

  .new_card_01 .new-c-photo img {
    width: 3.438rem;
    height: 3.438rem;
    margin: 0.625rem auto;
  }


  .new_card_01 p.new-card-para {
    line-height: 1rem;
    padding: 0.188rem 0;
  }

  .cmn_curat_txt {
    width: 85%;
  }

  .mc_search {
    padding: 0.938rem 1.25rem 1.875rem 1.25rem !important;
  }

  .scroll_class_list {
    height: 49vh ;
  }
}

@media only Screen and (max-width: 1290px) {
  .scroll_class_list {
    height: 52vh;
  }

  .curator_pop_form_change {
    height: 84vh;
  }

  .new-crd-titkle {
    margin-left: 1.25rem;
  }
}

@media only Screen and (max-width: 1200px) {
  .padding-rght span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding-left: 0.313rem;
    width: 100%;
  }

  .new-crd-titkle {
    margin-left: 1.25rem;
  }
}

@media only Screen and (max-width: 1155px) {
  .mat-form-field-infix {
    width: 10.625rem !important;
  }

  .extra-textarea-div {
    width: 60%;
  }

  .cmn_curat_txt {
    width: 83%;
  }

  .mat-radio-label-content {
    padding-top: 0.125rem !important;
    padding-left: 0.313rem !important;
  }

  .new-crd-titkle {
    margin-left: 1.25rem;
  }
}

@media only Screen and (max-width: 1024px) {
  .scroll_class_about {
    height: 67vh;
  }
  .scroll_class_list {
    height: 63vh;
  }

  .profile-edit-icn img {
    padding: 0.25rem;
  }
  .curator_card_main .new-crd-titkle p.curat_descript {
    padding-left: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .curator_card_main .new-crd-titkle h3 {
    padding-left: 1.25rem;
  }

  .curator_card_main p.new-card-para {
    padding-left: 1.25rem;
  }

  .card_num_02 img {
    width: 2.5rem;
    height: 2.5rem;
  }

  .card_num_02 p {
    padding-left: 0.813rem;
    margin: 0;
    line-height: 0.75rem;
  }

  .new_card_01 .new-c-photo img {
    width: 2.5rem;
    height: 2.5rem;
  }


  .new_card_01 p.new-card-para {
    line-height: 0.875rem;
  }

  .cmn_curat_txt {
    width: 80%;
  }

  .mat-form-field-infix {
    width: 8.75rem !important;
  }

  .extra-textarea-div {
    width: 55%;
  }

  .input-width-change {
    padding-right: 7.5rem !important;
  }

  .dotes-member-ul li span.dotmemspan {
    width: 0.375rem;
    height: 0.375rem;
  }

  .kt-header__topbar {
    background-color: transparent;
  }

  .kt-header-mobile {
    background-color: transparent !important;
  }

  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-toggler {
    display: none !important;
  }

  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toolbar-topbar-toggler {
    display: none !important;
  }

  .kt-header-mobile--fixed .kt-header-mobile {
    display: none !important;
  }

  .kt-header .kt-container {
    position: relative;
  }

  .dotes-member-ul li span.dotmemspan {
    width: 0.375rem;
    height: 0.375rem;
  }

  .card.archive_card {
    min-height: 8.75rem;
  }

  .archive_title {
    line-height: 1rem;
    white-space: initial;
  }

  .card.archive_card .title b {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text_on_image {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .new-crd-titkle {
    margin-left: 1.25rem;
  }

  .modal-content {
    background-color: transparent !important;
    border: 0.063rem solid transparent !important;
  }

  .modal-header {
    border: none !important;
  }
}
@media only Screen and (max-width: 875px) {
  #cdk-overlay-1 {
    width: 80% !important;
    max-width: auto !important;
  }
.whitePurpleTheme h1 {
  font-size: 1.25rem !important;
  }
  .info_material_icon_big{
  font-size: 1.3rem !important;
  }
  .mem-radio-btn{
    margin-right:0.188rem !important;
  }
  
 
  .mat-menu-panel {
    margin-left: auto !important;
  }
}

@media only Screen and (max-width: 835px) {
  .input-width-change {
    padding-right: 7.5rem !important;
  }

  input.my_search_input {
    height: 2.375rem;
    padding: 0.625rem 3.75rem 0.625rem 2.5rem;
  }

  .fa-search:before {
    top: 4.375rem;
    right: 2.5rem;
  }

  .archive_title {
    padding-bottom: 0.313rem;
  }

  mat-radio-button {
    margin-top: 1.25rem;
  }

  mat-radio-button .mat-radio-label {
    margin-bottom: 0.625rem !important;
  }

  .mat-form-field-infix {
    width: 6.25rem !important;
  }

  .extra-textarea-div {
    width: 40%;
  }

  .extra-textarea-div {
    width: 50%;
  }

  .mat-form-field-infix {
    width: 5.313rem !important;
  }

  .modal-open .modal {
    background: #271d50cc !important;
  }

  .modal-content {
    background-color: transparent !important;
    border: 0.063rem solid transparent !important;
  }

  .modal-header {
    border: none !important;
  }

  .mat-form-field-infix {
    width: 6.25rem !important;
  }
  #kt_header_brand {
    display: none;
  }

  .subheader_aside .option_area {
    display: none;
  }

  .subheader_left_img {
    width: 1.875rem;
    position: absolute;
    top: -2.938rem;
    right: 8.438rem;
  }

  .reports_card {
    padding: 2.5rem 1.25rem;
  }

  .survey3_img {
    top: 4.375rem;
    right: 1.563rem;
  }
}
@media screen and (max-width: 800px) {
  .contentEach {
    left: 0;
  }
}

@media only Screen and (max-width: 768px) {
  .curator_card_main p.new-card-para {
    padding-bottom: 0.938rem;
  }

  .input-width-change {
    padding-right: 7.5rem !important;
  }

  .row-flex {
    display: block;
  }

  input.my_search_input {
    height: 2.375rem;
  }

  .fa-search:before {
    right: 2.5rem;
  }

  .spill-icon {
    white-space: inherit;
    overflow: hidden;
    text-overflow: inherit;
    width: 100%;
    text-align: center;
  }

  .profile_img {
    width: 3.75rem !important;
    margin: 0.625rem auto !important;
    display: table;
  }

  mat-radio-button {
    margin-top: 1.25rem;
  }

  mat-radio-button .mat-radio-label {
    margin-bottom: 0.625rem !important;
  }

  .extra-textarea-div {
    width: 50%;
  }

  .mat-form-field-infix {
    width: 5.313rem !important;
  }

  .modal-open .modal {
    background: #271d50cc !important;
  }

  .modal-content {
    background-color: transparent !important;
    border: 0.063rem solid transparent !important;
  }

  .modal-header {
    border: none !important;
  }

  .mat-form-field-infix {
    width: 6.875rem !important;
  }
  #kt_header_brand {
    display: none;
  }

  .subheader_aside .option_area {
    display: none;
  }

  .subheader_left_img {
    width: 1.875rem;
    position: absolute;
    top: -2.938rem;
    right: 8.438rem;
  }

  .reports_card {
    padding: 2.5rem 1.25rem;
  }

  .survey3_img {
    top: 4.375rem;
    right: 1.563rem;
  }

  .archive_title {
    padding-bottom: 0.313rem;
  }

  .archive_img {
    width: 3.75rem;
    margin: 0 auto;
    height: 3.75rem !important;
  }

  .card.archive_card {
    min-height: 13.75rem;
  }

  .card.archive_card_mg_templa {
    min-height: auto;
  }

  .img_section_mirror {
    width: 100%;
    margin: 0.625rem auto;
  }

  .row-flex {
    display: block;
  }

  input.my_search_input {
    height: 2.375rem;
  }

  .spill-icon {
    white-space: inherit;
    overflow: hidden;
    text-overflow: inherit;
    width: 100%;
    text-align: center;
  }

  .profile_img {
    width: 3.75rem !important;
    margin: 0.625rem auto !important;
    display: table;
  }

  .card.archive_card .title {
    text-align: center;
  }

  .card.archive_card i {
    right: 0.625rem !important;
    bottom: 0.625rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  mat-dialog-container.mat-dialog-container {
  overflow-x: hidden;
  }

}
@media only Screen and (max-width: 776px) {
  mat-dialog-container.mat-dialog-container {
    width: 100% !important;
    display: block !important;
  }

  h1[id^="mat-dialog-title"] button.modal_close_button_top {
    margin-right: 1rem !important;
    margin-top: 1rem !important;
    background: transparent !important;
  }

  .cdk-overlay-pane {
    max-width: auto !important;
  }

  .mat-checkbox-layout {
    white-space: initial !important;
  }

  h1[id^="mat-dialog-title"] button.modal_button {
    margin-right: 0 !important;
    margin-top: 0 !important;
  }



  .scroll_class_list {
    height: 53vh ;
  }

  .curator_pop_form_chng {
    width: 100%;
  }

  .border-bt {
    border: none;
  }

  .input-width-change {
    padding-right: 7.5rem !important;
  }

  .mob-button-area {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .memb-select {
    width: 80%;
    margin-top: 0.25rem;
    margin-left: 0.938rem;
    margin-bottom: 0.5rem;
  }

  mat-radio-button {
    margin-top: 0rem;
    margin-left: 0.625rem;
  }

  mat-radio-button .mat-radio-label {
    margin-bottom: 0rem !important;
  }

  .mat-form-field-infix {
    width: 4.375rem !important;
  }

  .rc-anchor-logo-img {
    margin: 0.5rem 0rem 0 0rem !important;
  }

  .rc-anchor-invisible {
    height: 3.75rem;
    width: 15rem;
    display: flex;
  }

  mat-radio-button.mem-radio-btn .mat-radio-label {
    margin-left: 0.313rem !important;
  }

  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 1.125rem;
  }

  .archive_title {
    padding-bottom: 0.313rem;
  }

  .network_card {
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;
    margin: 0 0rem 0.5rem 0rem;
    text-align: center;
  }

  .archive_img {
    width: 3.75rem;
    margin: 0 auto;
  }

  .archive_title {
    line-height: 1rem;
    white-space: inherit;
  }

  .mob-button-area {
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  .memb-select {
    width: 80%;
    margin-top: 0.25rem;
    margin-left: 0.938rem;
    margin-bottom: 0.5rem;
  }

  .mat-drop-new {
    margin-left: 10.625rem;
  }

  mat-card-content.mat-card-content {
    max-height: 35rem !important;
  }

}
@media screen and (max-width: 700px) {
  .homeBox {
    width: 30rem;
    transform: translate(0, 0) !important;
  }

  kt-footer-buttons-home {
    bottom: 8.25rem;
  }
}

@media only Screen and (max-width: 576px) {
  .homeBox {
    width: 95%;
  }
  .mat-menu-panel {
    min-width: auto !important;
    max-width: auto !important;
    margin-left: auto !important;
  }
}

@media screen and (max-width: 480px) {
  kt-footer-buttons-home {
    bottom: 11.25rem;
  }
  .kt-sidebar {
    visibility: hidden !important;
    display: block;
    position: fixed;
  }

  .expandSidebar .kt-sidebar {
    visibility: visible !important;
    width: 13.438rem;
    transition: 0.5s width linear;
    z-index: 999999;
  }

  .kt-main {
    width: calc(100%) !important;
    transition: 0.5s width linear;
  }

  .expandSidebar .kt-main {
    width: calc(100%) !important;
    transition: 0.5s width linear;
  }

  .kt-header--fixed .kt-header {
    width: calc(100%) !important;
  }
}
@media only Screen and (max-width: 415px) {
  .mob-tr-radio .mat-radio-container {
    margin-left: 2.5rem;
  }
}

@media only Screen and (max-width: 376rem) {

}

@media only Screen and (max-width: 320px) {
  .mat-form-field-infix {
    width: 2.5rem !important;
  }

  .cdk-virtual-scroll-content-wrapper {
    position: relative !important;
  }

  mat-card-content.mat-card-content {
    max-height: 13.75rem !important;
  }
}






/* End Media Queries

/* style.scss */
/* You can add global styles to this file, and also import other style files */
@import "~material-design-icons/iconfont/material-icons.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "basic";

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
  margin: 0rem;
  margin-top: 0.5rem;
}

.fa-search,
.fa-filter {
  cursor: pointer;
}
.mat-ripple .mat-tab-label .mat-focus-indicator .mat-tab-label-active .ng-star-inserted,
.whitePurpleTheme .mat-card,
.whitePurpleTheme .mat-tab-group,
.whitePurpleTheme,
h1,
h2,
h3,
h4,
h5,
h6,
p,
td,
input,
textarea,
span,
select,
select option,
li,
a {
  font-family: "Lato";
}

img {
 object-fit: cover;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E") !important;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E") !important;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z'/%3E%3C/svg%3E") !important;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E") !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #a2d45e;
}

/*   end of radio edit  */

button.btn.go_btn {
  padding: 0.313rem 0.625rem;
}
.new-div-inpt {
  padding-left: 0.938rem;
}

.new-div-inpt-rht {
  padding-right: 0.938rem;
}
.mat-form-field.new-div-inpt,
.mat-form-field.new-div-inpt-rht {
  width: 50% !important;
  color: white;
}
/* Close Icon should fixed in Outside of Mat Dialog SCSS Starts */
@mixin brdClass($bgColor, $brd, $brdRadius) {
  background: $bgColor;
  border: $brd;
  border-radius: $brdRadius;
}

@mixin iconStyle($float, $top, $right) {
  float: $float;
  top: $top;
  right: $right;
}

.close-button {
  @include iconStyle(right, -1.125rem, -2.188rem);
}

.close-icon {
  transition: 1s ease-in-out;
  opacity: 0.5 !important;
}

::ng-deep .icon-outside .close-button {
  @include iconStyle(right, -3.25rem, -3.25rem);
}

::ng-deep .icon-outside .mat-dialog-container {
  overflow: unset;
}
.commonBtnClass {
  @include brdClass(transparent, 0.0625rem solid #CCC, 0.3125rem);
  color: #FFF;
}
.activeBtnClass {
  @include brdClass(#71abe4 !important, 0.0625rem solid #71abe4 !important, 0.3125rem !important);
}
.activeBtnClass>.mat-badge-content {
  background: #fff !important;
  color: #000 !important;
}

.scrollDivClass::-webkit-scrollbar-track {
  background: none;
}

.scrollDivClass::-webkit-scrollbar {
  width: 0.313rem;
}

.scrollDivClass::-webkit-scrollbar-thumb {
  @include brdClass(#c5c5c5 !important,
    0.188rem solid #c5c5c5 !important,
    0.375rem !important);
}

/* Close Icon should fixed in Outside of Mat Dialog SCSS Ends */

/* My Community Feed & Upcoming Dialogs Tab CSS Starts */
.myCommunityTabClass .mat-tab-body-content {
  padding-top: 0rem !important;
}

/* My Community Feed & Upcoming Dialogs Tab CSS Ends */

ngx-mat-datetime-content .ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-form-field {
  width: 2.5rem !important;
  max-width: 2.5rem !important;
}

ngx-mat-datetime-content .ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-button-wrapper {
  color: #fff !important;
}

ngx-mat-datetime-content .ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td button.mat-button.mat-button-base {
  border: 0.063rem solid white !important;
}

ngx-mat-datetime-content .actions .mat-button-wrapper mat-icon {
  color: #fff !important;
}

ngx-mat-datetime-content .actions button.mat-button.mat-button-base {
  border: 0.063rem solid white !important;
}

p.QuestionText {
  padding: 0rem 0rem 0rem 0rem;
  margin: 0;
  cursor: default;
}

#vedioBg {
  position: absolute;
  width: 100%;
  height: calc(100vh);
  left: 0;
  top: 0;
}

#vedioBg video {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.contentEach {
  color: #fff !important;
  padding: 0.938rem !important;
  position: relative;
  z-index: 1;
  border-bottom: 0.125rem solid #223466;
  position: absolute;
  bottom: 0;
}


.homeBox {
  position: absolute;
  z-index: 1;
  width: 30%;
  background: #fff !important;
  padding: 1.25rem;
  right: 0;
  left: 0;
  margin: 0 auto;
  border: 0.063rem solid #2c2d45;
  border-radius: 1.25rem;
  border-width: 0.125rem;
  border-style: solid;
}

.homeBox h1 {
  margin-bottom: 1.25rem;

  text-transform: uppercase;
}

.homeBox .innerBox {
  float: left;
  position: relative;
}

.homeBox .triangle {
  border: 1.875rem solid #fff;
  border-left-color: transparent;
  border-top: tan;
  width: 0;
  height: 0;
  border-bottom-color: transparent;
  border-right-width: 0.938rem;
  border-left-width: 0;
  position: absolute;
  bottom: -3.125rem;
  right: 0.938rem;
  z-index: 2;
}

.homeBox .triangle:after {
  content: "";
  border: 2rem solid #a6d566;
  width: 0;
  height: 0;
  border-left-color: transparent;
  border-top: tan;
  border-bottom-color: transparent;
  border-right-width: 0.938rem;
  border-left-width: 0;
  position: absolute;
  bottom: -2.063rem;
  z-index: 2;
}

.homeBox .triangle::before {
  content: "";
  border: 1.875rem solid #fff;
  border-left-color: transparent;
  border-top: tan;
  width: 0;
  height: 0;
  border-bottom-color: transparent;
  border-right-width: 0.688rem;
  transform: rotate3d(30deg, 30deg, 30deg);
  border-left-width: 0;
  position: absolute;
  bottom: -1.938rem;
  z-index: 3;
  right: -0.875rem;
}

.homeBox .homeBox-link {
  border-radius: 0.313rem;
  text-decoration: none;
  padding: 0.313rem;
}

.homeBox .triangle .circle {
  position: absolute;
  width: 0.938rem;
  height: 0.938rem;
  border-radius: 50%;
  background: #fff;
  z-index: 4;
  top: 2.188rem;
  right: -1.563rem;
}

.homeBox .triangle .circle:before {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 0.125rem solid #fff;
  z-index: 4;
  content: "";
  top: -0.281rem;
  left: -0.281rem;
}

kt-footer-buttons-home {
  position: fixed;
  bottom: 6.25rem;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: calc(6.25rem);
}
.mat-dialog-container .meaningCentralProfil {
  height: calc(100vh - 9.375rem) !important;
  margin-bottom: 1rem !important;
  padding-right: 0.5rem !important;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.add-diolog-pic-curator .img-thumbnail_for_model_window_image_curator {
  border-radius: 0.75rem !important;
}

.add-diolog-pic-curator,
.profile-editing-pad label {
  position: relative !important;
}

.profile-editing-pad label {
  width: 100% !important;
}

.add-diolog-pic-curator .profile-edit-icn-pop,
.profile-editing-pad .profile-edit-icn {
  position: absolute !important;
  right: 0.625rem !important;
  bottom: 0.625rem !important;

  @media only Screen and (min-width: 1600px) and (max-width: 1690px) {
    right: 0.3rem !important;
    bottom: 0.3rem !important;
  }
}

.add-diolog-pic-curator .file-input,
.profile-editing-pad .file-input {
  position: absolute !important;
}

.img-thumbnail {
  height: 21rem !important;
  width: 100% !important;
  object-fit: cover !important;
}

.side_bar_icon {
  width: 1.4rem !important;
  height: 1.4rem !important;
}

.hyperlink_hand {
  cursor: pointer;
  font-weight: 700 !important;
  text-transform: uppercase;
}

.search_no_data_found_image {
  border-radius: 0rem;
  padding: 0.625rem;
}

.top_left_sidebar {
  padding: 0.1rem !important;
}

.topicMenu {
  max-width: 30rem !important;
  width: 30rem !important;
  margin-left: 0rem !important;
  margin-top: 0.5rem !important;
}

.mediaFromat {
  max-width: 19.425rem !important;
  width: 19.425rem !important;
  margin-left: 0rem !important;
}

.moreOption {
  max-width: 19.5rem !important;
  width: 19.5rem !important;
}

.add_icon {
  font-size: 1.57rem !important;
  cursor: pointer;
  position: relative;
}

.mat-menu-panel {
  min-height: 2.5rem !important;
}

.note-editor .note-editing-area .note-editable,
.note-editor .note-editing-area .note-editable p,
.note-editor .note-editing-area .note-editable span {
  font-size: 1.125rem !important;
}

.img20 {
  width: 1.65rem;
  height: 1.65rem;
}

.br-50 {
  border-radius: 50%;
}

.br-10 {
  border-radius: 0.625rem;
}

.cursorp {
  cursor: pointer;
}

.cursorn {
  cursor: auto;
}

.spa-btn-change_new_img img.img25,
.img25 {
  width: 1.875rem;
  height: 1.875rem;
}

.img30 {
  width: 2rem;
  height: 2rem;
}

.img35 {
  width: 2.3rem;
  height: 2.3rem;
}

.img5rem {
  width: 5rem !important;
  height: 5rem !important;
}

.img18 {
  width: 1.5rem;
  height: 1.5rem;
}

.img15,
.profile-edit-icn img {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.img40 {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.color-user-request-white,
.colorw {
  color: #fff !important;
}

.coloro {
  color: #ce6729 !important;
}

.color-user-request {
  color: #b6b6bb;
}

.colorpw {
  color: #f57116;
}

.colorred {
  color: red;
}

.colory {
  color: yellow;
}

.colorhex {
  color: #8bc34a;
}

.colorstatus {
  color: #8bc34a;
}

.br-0 {
  border-radius: 0rem;
}

.logind {
  color: #ed7422 !important;
}

.login1 {
  color: #bdbdc4 !important;
}

.colorprogress {
  color: #ec7422 !important;
}

.color-settings {
  color: #343a5e !important;
}

.border0 {
  border: 0rem;
}

.whitePurpleTheme .mt-0 {
  margin-top: 0rem !important;
}

.whitePurpleTheme .ml-80 {
  margin-left: 6.7rem !important;
}

.whitePurpleTheme .ml-68 {
  margin-left: 5.5rem !important;
}

.whitePurpleTheme .pl-0 {
  padding-left: 0rem !important;
}

.whitePurpleTheme .w-33 {
  width: 33% !important;
}

.bs-10 {
  border-spacing: 0.625rem;
}

.pe-none {
  pointer-events: none;
}

.of-contain {
  object-fit: contain;
}

.list-style-none {
  list-style-type: none;
}

.z-index-2 {
  z-index: 2;
}

.whitePurpleTheme .justify-content-end {
  justify-content: end !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-inline {
  display: inline !important;
}

.d-contents {
  display: contents !important;
}

.d-none {
  display: none !important;
}

.pos-inherit {
  position: inherit;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.top-0px {
  top: 0px !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

.color-send-message {
  color: #cfd8dc;
}

.header-mobile-color {
  color: #271d50;
}

.info-mom {
  color: #ce7b10;
}

.vertical-align-top {
  vertical-align: top;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #fff;
  color: #000;
}

.card-guide:hover {
  box-shadow: none;
}

.timepicker-period__warning {
  background: #a2d45e !important;
  border-color: #a2d45e !important;
}

.timepicker-period__btn {
  border: 0 !important;
}

.margin_auto {
  margin: 0rem auto;
}

.text-decoration {
  text-decoration: none;
}

.whitePurpleTheme .section-title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2.125rem;
  color: #45454a;

  @media only Screen and (max-width: 1100px) {
    font-size: 1.35rem;
  }

  @media only Screen and (max-width: 800px) {
    font-size: 1.25rem;
  }
}

.whitePurpleTheme .section-title1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem ;
  line-height: 1.813rem;
  letter-spacing: -0.01em;


  @media only Screen and (max-width: 800px) {
    font-size: 1rem;
  }
}
.whitePurpleTheme .section-text li,
.whitePurpleTheme .section-text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.65rem;
  /* or 140% */

  letter-spacing: 0.01em;

  /* Neutrals/Neutral 800 */

  @media only Screen and (max-width: 1100px) {
    font-size: 1rem;
  }

  @media only Screen and (max-width: 800px) {
    font-size: 0.95rem;
  }
}

.whitePurpleTheme .arrow-highlight {
  color: #fff;
}

.whitePurpleTheme .italic-section {
  font-style: italic;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.65rem;
  /* identical to box height, or 140% */

  letter-spacing: 0.01em;

  /* Neutrals/Neutral 800 */

  @media only Screen and (max-width: 1100px) {
    font: size 0.95rem;
  }

  @media only Screen and (max-width: 800px) {
    font-size: 0.9rem;
  }
}

ul.ngx-select__choices.dropdown-menu.show,
.ngx-select__choices {
  background-color: #fff;
  width: 101.5%;
  height: 6rem;
  overflow-y: auto;
}

ul.ngx-select__choices.dropdown-menu.show::-webkit-scrollbar-thumb {
  background: #c2c5c5;
}

.search-for-guide-menu-select {
  padding-left: 0.875rem !important;

  @media screen and (min-width: 1900px) and (max-width: 2800px) {
    padding-left: 0.313rem !important;
  }

  @media screen and (min-width: 1600px) and (max-width: 1800px) {
    padding-left: 0.625rem !important;
  }

  @media screen and (min-width: 1400px) and (max-width: 1590px) {
    padding-left: 0.6rem !important;
  }

  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    padding-left: 0.25rem !important;
  }
}

.search-guide-drop ngx-select .ngx-select.dropdown ngx-select-choices ul.ngx-select__choices.dropdown-menu.show {
  width: 101%;
  overflow-y: auto;
  height: 6rem;
}
.cursor-default {
  cursor: default;
}
.multi-collapse span.ng-clear-wrapper,
.row.compareSurvey span.ng-clear-wrapper {
    position: relative;
  top: 6px;
}
.survey-radio .mat-radio-button .mat-radio-label{
  margin-bottom: 1.75rem !important;
}
.compareSurvey .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 1.125rem;
}

.compareSurvey .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left,
.compareSurvey .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  font-size: 1.5rem;
  padding-right: 1.5rem;
}
/* End styles.scss */

/* Base Framework */

body::-webkit-scrollbar {
  width: 0.313rem;
}

body::-webkit-scrollbar-track {
  background: none;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(197, 197, 197, 1);
  border-radius: 0.375rem;
  border: 0.188rem solid rgba(197, 197, 197, 1);
}

.report_container::-webkit-scrollbar {
  width: 0.313rem;
}

.report_container::-webkit-scrollbar-track {
  background: none;
}

.report_container::-webkit-scrollbar-thumb {
  background-color: rgba(197, 197, 197, 1);
  border-radius: 0.375rem;
  border: 0.188rem solid rgba(197, 197, 197, 1);
}

.scroll_class::-webkit-scrollbar {
  width: 0.313rem;
}

.scroll_class::-webkit-scrollbar-track {
  background: none;
}

.scroll_class::-webkit-scrollbar-thumb {
  background-color: rgba(197, 197, 197, 1);
  border-radius: 0.375rem;
  border: 0.188rem solid rgba(197, 197, 197, 1);
}
/* Removing Extra Class */
.curator_pop_form {
  width: 100%;
}
.fas.curator-fa {
  margin: 0 0.625rem;
}
mat-icon{
  font-size: 1.625rem !important;
height: 1.5rem;
width: 1.5rem;
margin-top: 0.188rem;
margin-left: 0.125rem;
margin-right: 0.125rem;
}
.curator_pop_form .row .col-sm-3 div { 
  width: 100% !important; 
  height: 100% !important; 
} 
.mat-card-actions {
  float: right;
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

select {
  -webkit-writing-mode: horizontal-tb !important;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0rem;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  cursor: default;
  margin: 0em;
  font: 400 13.3333px Arial;
  border-radius: 0rem;
  border-width: 0.063rem;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
  border-image: initial;
}
.t3n{
  top:-0.188rem !important;
}
.ng-select .ng-select-container .ng-value-container .ng-input>input{
  cursor: pointer !important;
}
a:hover{
  text-decoration: none !important;
}
.stat-p{
  height: 2.5rem;
}

/* End Removing Extra Class */