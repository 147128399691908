.fa-search,
.fa-filter {
  cursor: pointer;
}

.whitePurpleTheme,
h1,
h2,
h3,
h4,
h5,
h6,
p,
td,
input,
textarea,
span,
select,
select option,
li,
a {
  font-family: "Lato";
}

.file-input {
  opacity: 0;
}

img {
  object-fit: cover;
}

.curator-fa {
  margin: 0 0.625rem;
}

/*   end of radio edit  */
.search-for-guide-menu-select {
  padding-left: 0.875rem;
}

.cursor-default {
  cursor: default;
}

.kt-main::-webkit-scrollbar,
.meaningsphereAside::-webkit-scrollbar {
  width: 0;
}

.kt-main::-webkit-scrollbar-thumb,
.meaningsphereAside::-webkit-scrollbar-thumb {
  background: transparent !important;
}

.notloggedin .kt-main {
  height: auto;
}

.image-input,
.delete_image_video,
.delete_icon_img {
  cursor: pointer;
}

.community-box.inlin strong {
  display: block;
}